import {ensureDOMInstance} from '../dom-utils';
import share from 'callbag-share';
import pipe from 'callbag-pipe';
import map from 'callbag-map';
import merge from 'callbag-merge';
import filter from 'callbag-filter';
import fromEvent from 'callbag-from-event';
import scan from 'callbag-scan';
import asObservable from './asObservable';
import {parseConfig} from './ObservableConfig';

export const KEY_DOWN = 'keydown';
export const KEY_UP = 'keyup';

export const SPACE = 'Space';
export const PAGE_UP = 'PageUp';
export const PAGE_DOWN = 'PageDown';
export const END = 'End';
export const HOME = 'Home';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_UP = 'ArrowUp';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_DOWN = 'ArrowDown';

const CODES: [
  typeof SPACE,
  typeof PAGE_UP,
  typeof PAGE_DOWN,
  typeof END,
  typeof HOME,
  typeof ARROW_LEFT,
  typeof ARROW_UP,
  typeof ARROW_RIGHT,
  typeof ARROW_DOWN,
] = [
  SPACE,
  PAGE_UP,
  PAGE_DOWN,
  END,
  HOME,
  ARROW_LEFT,
  ARROW_UP,
  ARROW_RIGHT,
  ARROW_DOWN,
];

const KEY_CODES_2_CODES = {
  '32': SPACE,
  '33': PAGE_UP,
  '34': PAGE_DOWN,
  '35': END,
  '36': HOME,
  '37': ARROW_LEFT,
  '38': ARROW_UP,
  '39': ARROW_RIGHT,
  '40': ARROW_DOWN,
};

const getKeyCode = event => KEY_CODES_2_CODES[event.keyCode];

const isGestureKey = event => {
  const code = getKeyCode(event);
  return CODES.some(v => code === v);
};

const isSameKey = (eventA, eventB) =>
  eventB && eventA && getKeyCode(eventA) === getKeyCode(eventB);

const isRepeatKey = (eventA, eventB) =>
  isSameKey(eventA, eventB) &&
  eventB.type === eventA.type &&
  eventB.ctrlKey === eventA.ctrlKey &&
  eventB.shiftKey === eventA.shiftKey &&
  eventB.altKey === eventA.altKey &&
  eventB.metaKey === eventA.metaKey;

function getNearestFocusableNode(node) {
  if (node instanceof Document) return node;
  if (!(node instanceof HTMLElement)) return document;
  if (node.tabIndex >= 0) return node;
  return getNearestFocusableNode(node.parentNode);
}

const DEFAULT_INITIAL_STATE = {
  x: 0,
  y: 0,
  xDelta: 0,
  yDelta: 0,
  xInitial: 0,
  yInitial: 0,
  xPrev: 0,
  yPrev: 0,
  xVelocity: 0,
  yVelocity: 0,
  gesturing: false,
  key: null,
  repeat: null,
  type: null,
  time: Infinity,
  timeInitial: Infinity,
  duration: 0,
  elapsed: 0,
};

function updateGestureState(state, event) {
  const {timeStamp: time} = event;
  switch (event.type) {
    case KEY_DOWN:
      if (state.gesturing) {
        return {
          ...state,
          time,
          duration: time - state.time,
          elapsed: time - state.timeInitial,
          gesturing: true,
          type: event.type,
          key: getKeyCode(event),
          repeat: event.repeat,
        };
      } else {
        return {
          ...state,
          time,
          timeInitial: time,
          gesturing: true,
          xDelta: 0,
          yDelta: 0,
          xVelocity: 0,
          yVelocity: 0,
          type: event.type,
          key: getKeyCode(event),
          repeat: event.repeat,
        };
      }
    case KEY_UP:
      return {
        ...state,
        time,
        duration: time - state.time,
        elapsed: time - state.timeInitial,
        gesturing: false,
        type: event.type,
        key: getKeyCode(event),
        repeat: event.repeat,
      };
  }
  throw new Error(`Could not handle event ${event}`);
}

function initEventSourceState(config) {
  return {
    ...config,
    firstEvent: null,
    gesturing: false,
    canceled: false,
  };
}

function shouldPreventDefault(state) {
  return (
    state.event instanceof KeyboardEvent &&
    isGestureKey(state.event) &&
    state.preventDefault &&
    !state.event.defaultPrevented &&
    typeof state.event.preventDefault === 'function'
  );
}

function updateEventSourceState(state, action) {
  if (!isGestureKey(action)) return state;
  state.event = action;
  switch (action.type) {
    case KEY_DOWN: {
      if (state.firstEvent) {
        if (isRepeatKey(state.firstEvent, action)) {
          return state;
        }
      }
      state.firstEvent = action;
      state.gesturing = true;
      return state;
    }
    case KEY_UP: {
      if (state.firstEvent && isSameKey(state.firstEvent, action)) {
        state.firstEvent = null;
        state.canceled = false;
        state.gesturing = false;
        return state;
      }
      return state;
    }
  }
}

const extractEvent = ({event}) => event;

function createEventSource(element, config) {
  // Make sure we have a DOM element to observe.
  ensureDOMInstance(element, Element);
  // Parse and extract the config (with defaults).
  const parsedConfig = parseConfig(config);
  const eventSource = merge(
    fromEvent(getNearestFocusableNode(element), KEY_DOWN),
    fromEvent(document, KEY_UP),
  );
  return pipe(
    eventSource,
    scan(updateEventSourceState, initEventSourceState(parsedConfig)),
  );
}

export function createSource(elementOrSource, config) {
  const eventSource =
    typeof elementOrSource === 'function'
      ? elementOrSource
      : createEventSource(elementOrSource, config);

  let wasGesturing = false;
  const isGesturing = (sourceState): boolean => {
    if (sourceState.canceled) {
      return false;
    }
    if (shouldPreventDefault(sourceState)) {
      // eslint-disable-next-line no-unused-expressions,babel/no-unused-expressions
      sourceState.event?.preventDefault();
    }
    if (wasGesturing && !sourceState.gesturing) {
      // Let the 'end' event through.
      wasGesturing = sourceState.gesturing;
      return true;
    }
    wasGesturing = sourceState.gesturing;
    return sourceState.gesturing;
  };

  return share(
    pipe(
      eventSource,
      filter(isGesturing),
      map(extractEvent),
      scan(updateGestureState, DEFAULT_INITIAL_STATE),
    ),
  );
}

export function create(element, config) {
  const eventSource = share(createEventSource(element, config));
  return asObservable(createSource(eventSource), eventSource);
}

export default {create, createSource};
