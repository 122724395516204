import Observable from 'zen-observable';
import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

export class DebugObservable extends Observable {
  constructor(subscriber, debugSubscriber) {
    super(subscriber);
    // eslint-disable-next-line no-underscore-dangle
    this._debugSubscriber = debugSubscriber;
  }

  _debugSubscriber;
  _debugObservable;

  // eslint-disable-next-line no-underscore-dangle
  __debug(debugObserver, ...observerArgs) {
    // eslint-disable-next-line no-underscore-dangle
    if (!this._debugObservable) {
      // eslint-disable-next-line no-underscore-dangle
      this._debugObservable = new Observable(this._debugSubscriber);
    }
    // eslint-disable-next-line no-underscore-dangle
    const debugSubscription = this._debugObservable.subscribe(debugObserver);
    const subscription = this.subscribe(...observerArgs);
    return {
      get closed() {
        return subscription.closed && debugSubscription.closed;
      },
      unsubscribe(): void {
        subscription.unsubscribe();
        debugSubscription.unsubscribe();
      },
    };
  }
}

function asObservable(source, debugSource) {
  const subscriber = observer => {
    observer.next = observer.next.bind(observer);
    observer.error = observer.error.bind(observer);
    observer.complete = observer.complete.bind(observer);
    return pipe(
      source,
      subscribe(observer),
    );
  };
  if (debugSource) {
    return new DebugObservable(subscriber, debugObserver => {
      debugObserver.next = debugObserver.next.bind(debugObserver);
      debugObserver.error = debugObserver.error.bind(debugObserver);
      debugObserver.complete = debugObserver.complete.bind(debugObserver);
      return pipe(
        debugSource,
        subscribe(debugObserver),
      );
    });
  } else {
    return new Observable(subscriber);
  }
}

export default asObservable;
