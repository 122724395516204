/* eslint-disable max-lines */

import React, {Fragment, Component} from 'react';
import * as ReactDOM from 'react-dom';
import {Row} from 'jsxstyle';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import ImageCallout from '@components/ImageCallout';
import PreFooter from '@components/PreFooter';
import Button from '@components/Button';
import Headline from '@components/Headline';
import CardGrid from '@components/CardGrid';
import SectionTitle from '@components/SectionTitle';
import UnitCard from '@components/UnitCard';
import AmenitiesRow from '@components/AmenitiesRow';
import AmenitiesCarousel from '@components/Carousel/AmenitiesCarousel';
import Map from '@components/Map';
import FloorplanBrowser from '@components/FloorplanBrowser';
import {WHITE, ORANGE, BLUE, LIGHT_GREY, TRANSPARENT} from '@styles/colors';
import getAvailableUnitsFromProperty from '@util/getAvailableUnitsFromProperty';
import {
  stringToUrl,
  flatten,
  makeUniqueIds,
  getAddressFromAddressSet,
  getFloorplanName
} from '@util';

import type {PropertyType, UnitType, FloorplanType} from '@types-local';

import {
  SCHEMA_VICTORY,
  SCHEMA_EPIC,
  SCHEMA_345,
  SCHEMA_298_MULBERRY,
} from '@util/googleSchema';
import {AMENITIES_298_MULBERRY, AMENITIES_DEFAULT} from '@util/amenities';

import VictoryLogo from '@components/icons/VictoryLogo';
import EastLogo from '@components/icons/EastLogo';
import EpicLogo from '@components/icons/EpicLogo';
import MulberryLogo from '@components/icons/MulberryLogo';

type State = {
  randomSeed: number | null,
  propertyUnits: UnitType[] | null,
  propertyFloorplans: FloorplanType[] | null,
};

type Props = {
  data: {
    wagtail: {
      ...PropertyType,
    },
  },
};

function propertyHasUnits(property: PropertyType): boolean {
  if (!property.soldout) {
    const {floorplans} = property;
    const floorplanUnits = floorplans.filter(
      floorplan => floorplan.units && floorplan.units.length > 0,
    );
    const availableUnits = floorplanUnits
      .map(unit => {
        const {units} = unit;
        const filteredUnits = units.filter(u => u.available);
        return filteredUnits;
      })
      .filter(ar => ar.length > 0)
      .reduce(flatten, [])
      .reduce(makeUniqueIds, []);
    return Boolean(availableUnits.length > 0);
  }
}

function getPropertySpecificData(property) {
  if (property && property.name) {
    switch (property.name.toLowerCase()) {
      case '298 mulberry':
        return {
          description: 'Live creatively in NoHo',
          logo: <MulberryLogo />,
          introCopy: 'Live creatively in NoHo',
          buildingAmenitiesTitle: 'Vibrant Living',
          buildingAmenitiesDescription:
            'At 298 Mulberry, we invite you to create a lifestyle that truly fits you.',
          buildingAmenitiesList: [
            '24-hour attended lobby',
            'Fitness center',
            'On-site laundry facilities',
            'Pet friendly',
            'Ground floor CVS',
            'On-site parking available'
          ],
          apartmentFeaturesTitle: 'Apartments fitted for modern city life',
          apartmentFeaturesDescription:
            'When you live at 298 Mulberry, everything you need to design your lifestyle is right here.',
          apartmentFeaturesList: [
            'Washed oak flooring',
            'Spacious closets',
            'Marble countertop',
            'Stainless-steel kitchen appliance',
            'Fully renovated kitchens with dishwashers',
          ],
          neighborhoodTitle: 'The Best of Vibrant City Living ',
          neighborhoodDescription:
            'If you desire a neighborhood thats creative, yet chic. Laid back, yet energizing. Then NoHo is the next place you’ll want to call home.',
          googleSchema: SCHEMA_298_MULBERRY,
          onSiteLink: 'https://www.on-site.com/form_applications_updt.jsp?goal=6&attr=x20&property_id=190575&lease_id=0&unit_id=0&required=',
          amenities: AMENITIES_298_MULBERRY,
        };
      case 'the victory':
        return {
          description: 'Living Comes Alive in Hell’s Kitchen',
          logo: <VictoryLogo />,
          introCopy: "Your life, custom-made in Hell's Kitchen",
          buildingAmenitiesTitle: 'Have It All',
          buildingAmenitiesDescription:
            'At The Victory, we know that a great place to live means a whole lot more than just an amazing apartment. That’s why everything surrounding it is just as incredible.',
          buildingAmenitiesList: [
            'Lounge with fireplace, kitchen, movie theater and rentable private party room',
            'Equip fitness center with indoor basketball court, TRX equipment, yoga wall, free weights and more',
            'Furnished outdoor terraces with green lawn, BBQ grills and cabana-style seating',
            '46-floor rooftop deck',
            'On-site parking available',
          ],
          apartmentFeaturesTitle: 'Apartments Inspired By You',
          apartmentFeaturesDescription:
            'When you have an apartment at The Victory, you’re not just renting a place to lay your head, you’re getting the space to do your thing.',
          apartmentFeaturesList: [
            'Spacious apartments with ample room',
            'Homes with beautiful, expansive views of the city and river',
            'Gorgeous hardwood strip flooring',
            'Granite countertops in most units',
          ],
          neighborhoodTitle: 'Get Things Cooking in Hell’s Kitchen',
          neighborhoodDescription:
            'On the West Side of Midtown Manhattan, you don’t need to leave a few square blocks to find absolutely everything you love about New York City living.',
          googleSchema: SCHEMA_VICTORY,
          onSiteLink: 'https://www.on-site.com/apply/property/48039',
          amenities: AMENITIES_DEFAULT,
        };
      case 'the epic':
        return {
          description: 'The City Meets in NoMad',
          logo: <EpicLogo />,
          introCopy: 'Your life, custom-made in NoMad',
          buildingAmenitiesTitle: 'Better Living Under One Roof',
          buildingAmenitiesDescription:
            "At The Epic, you'll find a space that transforms a place of living into something that truly feels like a home.",
          buildingAmenitiesList: [
            'Lobby filled with unique, city-inspired art',
            'Equip fitness center with basketball court, TRX equipment, free weights and more',
            'Resident lounge perfect for coworking',
            'Furnished terraces with outdoor dining and grills',
            'On-site parking available',
          ],
          apartmentFeaturesTitle: 'Make it Yours',
          apartmentFeaturesDescription:
            'Find yourself in a luxury apartment where the way you like to live comes alive.',
          apartmentFeaturesList: [
            'Floor-to-ceiling windows providing beautiful, expansive views of the city',
            'Washer and dryer in most units',
            'Window shades in most units',
            'Gorgeous hardwood strip flooring',
          ],
          neighborhoodTitle: 'It’s All Right Here',
          neighborhoodDescription:
            'Get everything you need from New York City living when you live in The Epic in NoMad, where you’re surrounded by convenience and comfort.',
          googleSchema: SCHEMA_EPIC,
          onSiteLink: 'https://www.on-site.com/apply/property/48027',
          amenities: AMENITIES_DEFAULT,
        };
      case '345e94':
        return {
          description: 'A Lifestyle Made For You in Yorkville',
          logo: <EastLogo />,
          introCopy: 'Your life, custom-made on the Upper East Side',
          buildingAmenitiesTitle: 'It’s All Here',
          buildingAmenitiesDescription:
            'Whether you’re in the mood to relax on our expansive terrace, hit the gym, or get some work done by the fireplace in the lounge, you’ll find the space and amenities to do your thing.',
          buildingAmenitiesList: [
            'Equip fitness center including TRX equipment, yoga wall, and Peloton Bikes',
            'Lounge with fireplace, screening area, and kitchen',
            '5,000 square foot landscaped sun terrace with outdoor dining and grill',
            'Spacious lobby adorned with gorgeous art and serviced by friendly staff',
            'On-site parking available',
          ],
          apartmentFeaturesTitle: 'It’s All Yours',
          apartmentFeaturesDescription:
            'Our apartments are outfitted with absolutely everything you could ever need in a living space and are designed with you, and only you, in mind.',
          apartmentFeaturesList: [
            'Spacious apartments with ample room',
            'Homes with beautiful, expansive views of the city and river',
            'Kitchens with Bertazzoni ranges, Bosch dishwashers and ice and water dispensing refrigerators',
          ],
          neighborhoodTitle: 'Slow Things Down a Little on the UES',
          neighborhoodDescription:
            'Enjoy the small-town vibe of the UES with cuisines from around the world, great coffee shops, and speciality gourmet stores',
          googleSchema: SCHEMA_345,
          onSiteLink: 'https://www.on-site.com/apply/property/48038',
          amenities: AMENITIES_DEFAULT,
        };
      default:
        return null;
    }
  }
}

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class Property extends Component<Props, State> {
  constructor(props) {
    super(props);

    const {data} = props;
    const {property} = data.wagtail;
    const floorplansFixedNames = property.floorplans.map(function (floorplan){
      return {...floorplan, name: getFloorplanName(floorplan.name)};
    })

    this.state = {
      randomSeed: 0.5 - Math.random(),
      propertyUnits: getAvailableUnitsFromProperty(property),
      propertyFloorplans: floorplansFixedNames,
      propertySpecificData: getPropertySpecificData(property),
    };
  }

  componentWillUnmount() {
    if (this.state.randomSeed) {
      this.setState({
        randomSeed: null,
      });
    }
    if(this.props.location.pathname.includes("mulberry")){
      let mulberryChatIcon = document.getElementsByClassName('rcc-app-cta-wrap')[0];
      let mulberryChatBody = document.getElementById('conversations-webchat-container');
      let mulberryChatScript = document.getElementsByClassName('script-mulberry-chat')[0];
      if(mulberryChatScript && mulberryChatIcon && mulberryChatBody){
        mulberryChatIcon.remove();
        mulberryChatBody.remove();
        mulberryChatScript.remove();
      }
    }
  }

  componentDidMount(){
    if(this.props.location.pathname.includes("mulberry")){
      const script = document.createElement("script");
      script.className = "script-mulberry-chat";
      script.type = "text/javascript";
      script.src = "//webchat.omni.cafe/conversations/ecdedef7-6ac9-435e-9f8b-093d55598098/app.js?property=ecdedef7-6ac9-435e-9f8b-093d55598098&propertyName=Test 298 Mulberry&channelUuid=2c68fb6f-d326-11ed-8f38-42010aa8d42b";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  componentDidUpdate(){
    let mulberryChatIcon = document.getElementsByClassName('rcc-app-cta-wrap')[0];
    let mulberryChatBody = document.getElementById('conversations-webchat-container');
    if(this.props.location.pathname.includes("mulberry")){
      if(mulberryChatIcon && mulberryChatBody){
        mulberryChatIcon.style.display = "block";
        mulberryChatBody.style.display = "block";
      }
    }else{
      if(mulberryChatIcon && mulberryChatBody){
        mulberryChatIcon.style.display = "none";
        mulberryChatBody.style.display = "none";
      }
    }
  }

  render() {
    const {data} = this.props;
    const {property} = data.wagtail;
    const hideElements = property.name.toLowerCase() === 'the victory' || property.name.toLowerCase() === '345e94';  
    const {
      name,
      propertypageSet,
      description,
      address,
      heroImage,
      buildingImages,
      apartmentImages,
      neighborhoodImages,
      phoneNumber,
    } = property;

    const [{seoTitle, searchDescription}] = propertypageSet;
    const [{name: neighborhoodName}] = address.neighborhood;

    const {url: neighborhoodImage} =
      neighborhoodImages.length > 0 && neighborhoodImages[0].image;

    const {propertySpecificData} = this.state;

    // Determine if the offer should be hidden based on property name
    const isSpecialProperty = property.name.toLowerCase() === 'the victory' || property.name.toLowerCase() === '345e94';

    const heroProps = {
      background: `linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${heroImage.image.url}) no-repeat`,
      noOffer: isSpecialProperty, // Determine based on property name
    };
    
    return (
      <Fragment>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={searchDescription} />
          <script type="application/ld+json">
            {JSON.stringify(propertySpecificData.googleSchema)}
          </script>
        </Helmet>
        <Layout
          contactUsQuery={`?subject=Property&property=${property.name}`}
          hideHeaderAndFooter={property.name.toLowerCase() === 'the victory' || property.name.toLowerCase() === '345e94'}
        >          
          <Hero {...heroProps}>
            {propertySpecificData && propertySpecificData.logo && propertySpecificData.logo}
            <Headline
              headlineType="h1"
              color={WHITE}
              fontSize="4.5rem"
              width="50%"
              lineHeight="4.5rem"
              marginTop="20px"
              smFontSize="3rem"
              smWidth="90%"
              smMargin="0 auto"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: propertySpecificData && propertySpecificData.description ? propertySpecificData.description : name,
                }}
              />
            </Headline>
            {/* Conditional rendering for The Victory or 345E94 */}
            {(property.name.toLowerCase() === 'the victory' || property.name.toLowerCase() === '345e94') && (
              <div style={{ 
                  display: 'flex', // Use flexbox to align children
                  flexDirection: 'column', // Stack children vertically
                  alignItems: 'center', // Center children horizontally
                  color: '#ffffff', // Ensure text color is white
                  textAlign: 'center', // Center the text alignment
                }}>
                <h2 style={{ 
                  fontFamily: 'abril-fatface, serif', 
                  fontSize: '2rem', 
                  fontWeight: '300', 
                  marginBottom: '20px', // Adjust spacing as needed
                  marginTop: '0px',
                  marginRight: '10%',
                  marginLeft: '10%',
                  lineHeight: '1.4'
                }}>
                  {property.name}, is no longer part of the Fetner portfolio, but we invite you to learn more about Fetner Properties and our available homes {' '}
                  <a href={property.name.toLowerCase() === 'the victory' ? 'https://fetner.com/' : 'https://fetner.com/'} target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>
                    here.
                  </a>
                </h2>
                <a
                  href={property.name.toLowerCase() === 'the victory' ? 'https://fetner.com/' : 'https://fetner.com/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    maxWidth: '200px', // Set the maximum width
                  }}
                  className="button-transition" // Use this class for hover effect if applicable
                >
                  More Info
                </a>
              </div>
            )}
          </Hero>

        {!hideElements && (
          <>
            <Section>
              <Row
                width="90%"
                margin="0 auto"
                maxWidth="1360px"
                mediaQueries={mediaQueries}
                smFlexDirection="column"
              >
                <SectionTitle
                  headline={
                    propertySpecificData && propertySpecificData.introCopy
                  }
                  copy={description}
                  textAlign="left"
                  alignItems="flex-start"
                  mediaQueries={mediaQueries}
                  smWidth="90%"
                  smMargin="0 0 40px 0"
                >
                  <Row>
                    {property.name === 'The Epic' && (
                        <Button
                            buttonLink="https://theepic.com/"
                            buttonText="apply now"
                            buttonColor={ORANGE}
                            margin="0 0 20px 0"
                        />
                    )}
                    {property.name !== 'The Epic' && propertySpecificData.onSiteLink &&
                    <Button
                      buttonText="apply now"
                      buttonLink={propertySpecificData.onSiteLink}
                      buttonColor={ORANGE}
                      margin="0 20px 0 0"
                    />
                    }
                    { propertyHasUnits(property) 
                      ?
                      (<Button
                        buttonText="view availabilities"
                        buttonLink={ property.name === 'The Epic'
                            ? 'https://theepic.com/'
                            : `/search/?neighborhood=${stringToUrl(
                              neighborhoodName,
                            )}`}
                        buttonColor={BLUE}
                      />)
                      :
                      (<Button
                        buttonText="contact us"
                        buttonLink={ "/contact/?subject=Property&property="+ property.name }
                        buttonColor={BLUE}
                        margin="0 20px 0 0"
                      />)
                    }   
                  </Row>
                </SectionTitle>
                <Map
                  address={getAddressFromAddressSet([address])}
                  neighborhood={neighborhoodName}
                  phoneNumber={phoneNumber}
                  width="50%"
                  mediaQueries={mediaQueries}
                  smWidth="100%"
                />
              </Row>
            </Section>
            <Section>
              <AmenitiesRow amenities={propertySpecificData.amenities} />
            </Section>
            <Section>
              <Headline color={BLUE}>Property Gallery</Headline>
              <AmenitiesCarousel
                data={buildingImages}
                eyebrow={'Building Amenities'}
                headline={
                  propertySpecificData &&
                  propertySpecificData.buildingAmenitiesTitle
                }
                copy={
                  propertySpecificData &&
                  propertySpecificData.buildingAmenitiesDescription
                }
                list={
                  propertySpecificData &&
                  propertySpecificData.buildingAmenitiesList
                }
                fetnerLogo
              />
              <AmenitiesCarousel
                data={apartmentImages}
                imageLeft
                eyebrow={'Apartment Features'}
                headline={
                  propertySpecificData &&
                  propertySpecificData.apartmentFeaturesTitle
                }
                copy={
                  propertySpecificData &&
                  propertySpecificData.apartmentFeaturesDescription
                }
                list={
                  propertySpecificData &&
                  propertySpecificData.apartmentFeaturesList
                }
              />
              <ImageCallout
                eyebrow="Neighborhood"
                headline={
                  propertySpecificData && propertySpecificData.neighborhoodTitle
                }
                copy={
                  propertySpecificData &&
                  propertySpecificData.neighborhoodDescription
                }
                imageSrc={neighborhoodImage}
                textAlign="left"
                background={LIGHT_GREY}
                imgMaxHeight="600px"
                mediaQueries={mediaQueries}
                smFlexDirection={'column-reverse !important'}
              >
                <Row>
                  <Button
                    buttonText={`check out ${neighborhoodName}`}
                    buttonLink={`/neighborhoods/${stringToUrl(
                      neighborhoodName,
                    )}/`}
                    buttonColor={BLUE}
                    margin="0 20px 0 0"
                  />
                </Row>
              </ImageCallout>
            </Section>
            {propertyHasUnits(property) && (
              <Section background={ORANGE} margin="0 auto" padding="80px 0">
                <Headline color={WHITE}>
                  {`All Available Units at ${name}`}
                </Headline>
                {(property.name === 'The Epic') && (
                    <div style={{maxWidth: '300px', margin: '0 auto'}}>
                      <Button
                          buttonLink="https://theepic.com"
                          buttonText="View Availabilities at The Epic"
                          buttonColor={TRANSPARENT}
                          buttonBorder={`1px solid ${WHITE}`}
                          buttonHeight={'50px'}
                          margin="0 auto"
                          maxWidth={'300px'}
                          style={{ maxWidth: '300px' }}
                      />
                    </div>
                )}
                <div style={{
                  display: property.name === 'The Epic' ? 'none' : 'block',
                }}>
                  <CardGrid
                    data={this.state.propertyUnits}
                    maxWidth="1360px"
                    gridItem={UnitCard}
                    gridTemplateColumns={'repeat(auto-fit, minmax(400px, 1fr))'}
                    mq={mediaQueries}
                    smGridTemplateColumns={'repeat(auto-fit, minmax(320px, 1fr))'}
                    gridGap="40px"
                    margin="0 auto"
                    padding="0 20px"
                  />
                </div>
              </Section>
            )}
            {property.name !== 'The Epic' && property.floorplans.length > 0 && (
              <Section background={WHITE} padding="80px 0">
                <FloorplanBrowser
                  floorplans={this.state.propertyFloorplans}
                  propertyName={property.name}
                />
              </Section>
            )}
            <PreFooter
              headline={`Interested in becoming a resident at ${name}?`}
              copy="Get in touch with us to find your perfect Fetner apartment."
              queryParams={ "?subject=Property&property="+ property.name }
              logo
            />
          </>
        )}
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  query($salesforceId: String!) {
    wagtail {
      property(salesforceId: $salesforceId) {
        name
        soldout
        description
        phoneNumber
        propertypageSet {
          seoTitle
          searchDescription
        }
        address {
          street
          city
          state
          postalCode
          neighborhood {
            name
            addressSet {
              street
              city
              state
              postalCode
            }
          }
        }
        heroImage {
          image {
            url
          }
        }
        buildingImages {
          image {
            url
          }
        }
        apartmentImages {
          image {
            url
          }
        }
        neighborhoodImages {
          image {
            url
          }
        }
        floorplans {
          id
          name
          sortOrder
          beds
          baths
          squareFeet
          salesforceId
          units {
            id
            slug
            name
            price
            available
            availableDate
          }
          image {
            url
          }
        }
      }
    }
  }
`;