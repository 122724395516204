import React from 'react';
import {Col, Row} from 'jsxstyle';

import {BLUE, GREY} from '@styles/colors';
import Headline from '@components/Headline';
import Concierge from './icons/Concierge';
import Laundry from './icons/Laundry';
import Fitness from './icons/Fitness';
import Lounge from './icons/Lounge';
import Pet from './icons/Pet';
import Terrace from './icons/Terrace';
import {arrayOf, string} from 'prop-types';

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

const AmenitiesRow = ({amenities}) => (
  <Col>
    <Headline color={BLUE} textAlign="center">
      building amenities
    </Headline>
    <Row
      alignItems="center"
      justifyContent="space-around"
      padding="0 40px"
      height="90px"
      mediaQueries={mediaQueries}
      smHeight="auto"
      smFlexDirection="column"
      smAlignItems="flex-start"
    >
      {amenities.map(amenity => (
        <Col
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          mediaQueries={mediaQueries}
          smFlexDirection="row"
          smMargin="20px 0"
          smWidth="100%"
        >
          {amenity.toLowerCase() === '24-hour concierge' && <Concierge />}
          {amenity.toLowerCase() === 'fitness center' && <Fitness />}
          {amenity.toLowerCase() === 'resident lounge' && <Lounge />}
          {amenity.toLowerCase() === 'pet-friendly' && <Pet />}
          {amenity.toLowerCase() === 'outdoor terrace' && <Terrace />}
          {amenity.toLowerCase() === 'laundry facility' && <Laundry />}
          <span style={{color: GREY}}>{amenity}</span>
        </Col>
      ))}
    </Row>
  </Col>
);

AmenitiesRow.propTypes = {
  amenities: arrayOf(string),
};

export default AmenitiesRow;
