/* eslint no-underscore-dangle: "off" */

function weigh(value, max, scale = 0) {
  let weight = 1;
  if (!scale) return weight;
  if (scale > 0) {
    weight = ((max - value) / max) * scale;
  } else if (scale < 0) {
    weight = ((value + 1) / max) * Math.abs(scale);
  }
  return weight;
}

export default class MovingAverage {
  constructor(options) {
    this._size = options?.size ?? 10;
    this._count = 0;
    this._scale = options?.weight ?? 1;
    this._round = options?.round ?? false;
    if (!this._size || !(this._size > 0)) {
      throw new Error('A positive size is required!');
    }
    this._store = new Array(this._size);
    this._pointer = 0;
    this._delta = 0;
    this._count = 0;
    this._average = null;
  }

  slice(start, end) {
    start = start ?? 0;
    if (start > this._size) start = this._size;
    if (start < 0) start = Math.max(0, this._size + start);

    end = end ?? this._size;
    if (end > this._size) end = this._size;
    if (end < 0) end = Math.max(start, this._size + end);
    if (end < start) end = start;

    const size = end - start;
    if (!size) {
      return new MovingAverage({
        size: 1,
        weight: this._scale,
        round: this._round,
      });
    }

    const sliced = new MovingAverage({
      size,
      weight: this._scale,
      round: this._round,
    });
    const pCount = Math.min(this._count, this._size);
    const pStart = (this._pointer + 1 - pCount + pCount) % pCount;
    const count = Math.min(size, pCount);
    let i = 0;
    while (i < count) {
      const index = (pStart + start + i) % pCount;
      const value = this._store[index];
      sliced.push(value);
      i++;
    }
    return sliced;
  }

  push(v) {
    this._pointer = this._count
      ? (this._pointer + 1) % this._size
      : this._pointer;
    this._store[this._pointer] = v;
    this._average = null;
    this._delta += v;
    if (this._count < this._size) this._count += 1;
  }

  peek() {
    // let count = Math.min(this._count, this._size);
    return this._store[this._pointer] ?? NaN;
  }

  reset() {
    this._store = new Array(this._size);
    this._pointer = 0;
    this._delta = 0;
    this._count = 0;
    this._average = null;
  }

  get deviation() {
    return Math.abs(this.peek() - this.value);
  }

  get delta() {
    return this._delta;
  }

  get rolling() {
    return this._count >= this._size;
  }

  get value() {
    if (this._average === null) {
      let sum = 0;
      let sumWeight = 0;
      const count = Math.min(this._count, this._size);
      let i = 0;

      while (i < count) {
        const index = (this._pointer - i + count) % count;
        const value = this._store[index];
        if (value !== null) {
          const weight = weigh(i, count, this._scale);
          sumWeight += weight;
          sum += value * weight;
        }
        i += 1;
      }
      const average = sum / sumWeight;
      this._average = this._round ? Math.round(average) : average;
    }
    return this._average;
  }

  valueOf() {
    return this.value;
  }
}
