// @flow

import React, {useCallback, useMemo, useState, useRef} from 'react';
import type {Element} from 'react';
import {Row, Col, InlineBlock} from 'jsxstyle';
import {useGesture} from '../../libs/gesture-catcher';
import {useSpring, config, animated} from 'react-spring';
import {NEXT, PREVIOUS, usePageGesture} from '../../libs/page-gesture';

import Headline from '@components/Headline';
import Button from '@components/Button';
import {BLACK, ORANGE} from '@styles/colors';
import FloorPlanCard from '@components/FloorPlanCard';

import type {FloorplanType} from '@types-local';

type FloorplanBrowserProps = {
  propertyName: string,
  floorplans: FloorplanType[],
};

const mediaQueries = {
  sm: 'screen and (max-width: 500px)',
  lg: 'screen and (min-width: 1280px)',
};

const CARD_WIDTH = 350;

function normalizeBedsQuery(beds: number): string | null {
  switch (beds) {
    case 0:
      return 'Studio';
    case 1:
      return 'One Bedroom';
    case 2:
      return 'Two Bedroom';
    case 3:
      return 'Three Bedroom';
    case 4:
      return 'Four Bedroom';
    default:
      return null;
  }
}

function getFloorplanTypes(floorplans: FloorplanType[]): Array<string | null> {
  const types = floorplans
    .map(floorplan => floorplan.beds)
    .sort((x, y) => x - y)
    .map(sortedFloorplan => normalizeBedsQuery(sortedFloorplan));
  const uniqueTypes = Array.from(new Set(types));
  return uniqueTypes;
}

function sortFloorplans(floorplans) {
  //return floorplans.sort((x, y) => x.sortOrder - y.sortOrder);
  return floorplans.sort(function compareFn(a, b) { 
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    return 0;
  });
}

export default function FloorplanBrowser({
  propertyName,
  floorplans,
}: FloorplanBrowserProps): Element<*> {
  const [currentCategory, setCategory] = useState({
    name: null,
    active: false,
  });
  const [, flipUpdateBit] = useState(false);
  const gestureConfig = useMemo(() => ({preventDefault: true}), []);
  const forceUpdate = useCallback(() => flipUpdateBit(v => !v), []);
  const activeSlide = useRef(0);
  const containerRef = useRef(null);
  const [transform, setTransform] = useSpring(() => ({
    config: config.default,
    transform: `translateX(0px)`,
  }));

  const typesFloor = getFloorplanTypes(floorplans);

  const handlePrev = useCallback(() => {
    activeSlide.current = Math.max(0, activeSlide.current - 1);
    setTransform({
      transform: `translateX(${activeSlide.current * -CARD_WIDTH}px)`,
    });
    forceUpdate();
  }, [setTransform, forceUpdate]);

  const handleNext = useCallback(() => {
    activeSlide.current = Math.min(
      floorplans.length - 1,
      activeSlide.current + 1,
    );
    setTransform({
      transform: `translateX(${activeSlide.current * -CARD_WIDTH}px)`,
    });
    forceUpdate();
  }, [setTransform, floorplans.length, forceUpdate]);

  const hasPrev = activeSlide.current > 0;
  const hasNext = activeSlide.current < floorplans.length - 1;

  const pageHandler = useCallback(
    action => {
      switch (action) {
        case NEXT:
          if (hasNext) {
            handleNext();
          }
          break;
        case PREVIOUS:
          if (hasPrev) {
            handlePrev();
          }
          break;
      }
      setTransform({
        transform: `translateX(${activeSlide.current * -CARD_WIDTH}px)`,
      });
      forceUpdate();
    },
    [handleNext, handlePrev, hasNext, hasPrev, forceUpdate, setTransform],
  );

  const handleFilterButtonClick = useCallback(
    event => {
      event.persist();
      const name = event.target.innerHTML;
      setCategory(prevCategory => {
        if (!prevCategory.name) {
          setTransform({
            transform: `translateX(0px)`,
          });
          return {name, active: true};
        }
        if (prevCategory.name === name) {
          return {active: false};
        }
        if (prevCategory.name !== name) {
          setTransform({
            transform: `translateX(0px)`,
          });
          return {name, active: true};
        }
      });
    },
    [setCategory, currentCategory, setTransform],
  );

  const updatePagination = usePageGesture(pageHandler, {
    orientation: 'horizontal',
  });

  const handleGesture = useCallback(
    gesture => {
      setTransform({
        immediate: gesture.gesturing,
        transform: `translateX(${gesture.xDelta +
          activeSlide.current * -CARD_WIDTH}px)`,
      });
      updatePagination(gesture);
    },
    [updatePagination, setTransform],
  );

  useGesture(containerRef, handleGesture, {
    ...gestureConfig,
    passive: false,
  });

  return (
    <div>
    { typesFloor.length > 0 &&
    <Col
      alignItems="center"
      justifyContent="center"
      width="100%"
      maxWidth="1360px"
      margin="0 auto"
      overflow="hidden"
    >

      <Row>
        <Headline
          smWidth="70%"
          smMargin="0 auto"
        >{`Floor Plans at ${propertyName}`}</Headline>
      </Row>
       
      <Row
        margin="0 auto 50px auto"
        width="100%"
        justifyContent="center"
        alignItems="center"
        mediaQueries={mediaQueries}
        smJustifyContent="center"
      >

        {(propertyName === 'The Epic') && (
            <div>
              <Button
                  buttonLink="https://theepic.com/"
                  buttonText="View Floorplans at The Epic"
                  buttonColor={ORANGE}
                  buttonHeight={'50px'}
                  buttonWidth="fit-content"
                  margin="0 0 20px 0"
              />
            </div>
         )}
        {(propertyName !== 'The Epic') && typesFloor.map(type => (
          <InlineBlock
            key={type}
            component="button"
            textTransform="capitalize"
            background="transparent"
            textDecoration="underline"
            border="none"
            fontSize="16px"
            fontFamily="proxima-nova"
            cursor="pointer"
            margin="0 10px"
            height="50px"
            whiteSpace="nowrap"
            willChange="color"
            color={
              currentCategory && type === currentCategory.name ? ORANGE : BLACK
            }
            props={{onClick: handleFilterButtonClick}}
          >
            {type}
          </InlineBlock>
        ))}
      </Row>
      {(propertyName !== 'The Epic') && (<animated.div
        ref={containerRef}
        className={'scroll-floorplans'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '40px 0',
          width: '100%',
          ...transform,
        }}
      >
        {currentCategory && currentCategory.name
          ? sortFloorplans(floorplans)
              .filter(
                floorplan =>
                  normalizeBedsQuery(floorplan.beds) === currentCategory.name,
              )
              .map(floorplan => (
                <Col key={floorplan.name} margin="0 20px">
                  <FloorPlanCard {...floorplan} propertyName={propertyName} />
                </Col>
              ))
          : sortFloorplans(floorplans).map(floorplan => (
              <Col key={floorplan.name} margin="0 20px">
                <FloorPlanCard {...floorplan} propertyName={propertyName} />
              </Col>
            ))}
      </animated.div>)}
    </Col>
     }
     </div>
  );
}
