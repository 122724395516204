import {useRef, useCallback, useEffect, useState} from 'react';

function dispatchGestureDebug(handler, event) {
  if (event && typeof handler === 'function') handler(event);
}

function dispatchGestureStateChange(handler, state, lastState) {
  if (state) {
    if (typeof handler === 'function') {
      handler(state);
    } else {
      if (state.gesturing) {
        if (!lastState || !lastState.gesturing) {
          if (handler && typeof handler.onStart === 'function') {
            handler.onStart(state);
          }
        } else if (lastState && lastState.gesturing) {
          if (handler && typeof handler.onMove === 'function') {
            handler.onMove(state);
          }
        }
      } else if (!lastState || lastState.gesturing) {
        if (handler && typeof handler.onEnd === 'function') {
          handler.onEnd(state);
        }
      }
    }
  }
}

function isDebugHandler(handler) {
  return (
    handler &&
    typeof handler !== 'function' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-underscore-dangle
    typeof handler.__debug === 'function'
  );
}

export function useObservableGestureEffect(Observable, ref, handler, config) {
  const lastState = useRef(null);
  const subscribed = useRef(false);
  const [subscriptions] = useState(() => new Map());

  const cleanupSubscriptions = useCallback(() => {
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    subscriptions.clear();
    subscribed.current = false;
  }, [subscriptions]);

  // eslint-disable-next-line no-underscore-dangle
  const debugHandler = isDebugHandler(handler) ? handler.__debug : null;

  const handleGestureDebug = useCallback(
    function handleGestureDebug(event) {
      if (subscribed.current && debugHandler) {
        dispatchGestureDebug(debugHandler, event);
      }
    },
    [debugHandler],
  );

  const handleGestureStateChange = useCallback(
    function handleGestureState(state) {
      if (subscribed.current && handler) {
        dispatchGestureStateChange(handler, state, lastState.current);
      }
      lastState.current = state;
    },
    [handler],
  );

  // Cleanup all subscriptions whenever
  // the handler or config changes, and on unmount.
  useEffect(() => cleanupSubscriptions, [
    cleanupSubscriptions,
    handleGestureStateChange,
    handleGestureDebug,
    config,
  ]);

  useEffect(function subscribeIfNecessary() {
    const element = ref.current;
    if (!element || !subscriptions.has(element)) {
      cleanupSubscriptions();
      if (element) {
        const subject = Observable.create(element, config);
        subscriptions.set(
          element,
          handleGestureDebug && '__debug' in subject
            ? // eslint-disable-next-line no-underscore-dangle
              subject.__debug(handleGestureDebug, handleGestureStateChange)
            : subject.subscribe(handleGestureStateChange),
        );
        subscribed.current = true;
      }
    }
  });
}
