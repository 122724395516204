export const AMENITIES_DEFAULT = [
  '24-hour Concierge',
  'Fitness Center',
  'Resident Lounge',
  'Pet-Friendly',
  'Outdoor Terrace',
];

export const AMENITIES_298_MULBERRY = [
  '24-hour Concierge',
  'Fitness Center',
  'Resident Lounge',
  'Pet-Friendly',
  'Laundry Facility',
];
