import {ensureDOMInstance} from '../dom-utils';
import share from 'callbag-share';
import pipe from 'callbag-pipe';
import map from 'callbag-map';
import scan from 'callbag-scan';
import merge from 'callbag-merge';
import filter from 'callbag-filter';
import fromEvent from 'callbag-from-event';
import asObservable from './asObservable';
import {HORIZONTAL, VERTICAL} from './Orientation';
import {parseConfig} from './ObservableConfig';

export {HORIZONTAL, VERTICAL};

export const TOUCH_START = 'touchstart';
export const TOUCH_MOVE = 'touchmove';
export const TOUCH_END = 'touchend';

const DEFAULT_INITIAL_STATE = {
  x: 0,
  y: 0,
  xDelta: 0,
  yDelta: 0,
  xInitial: 0,
  yInitial: 0,
  xPrev: 0,
  yPrev: 0,
  xVelocity: 0,
  yVelocity: 0,
  gesturing: false,
  type: null,
  time: Infinity,
  timeInitial: Infinity,
  duration: 0,
  elapsed: 0,
};

function updateGestureState(state, event) {
  const {timeStamp: time} = event;
  switch (event.type) {
    case TOUCH_START:
    case TOUCH_MOVE:
      if (state.gesturing) {
        return {
          ...state,
          time,
          duration: time - state.time,
          elapsed: time - state.timeInitial,
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
          xPrev: state.x,
          yPrev: state.y,
          xDelta: event.touches[0].clientX - state.xInitial,
          yDelta: event.touches[0].clientY - state.yInitial,
          xVelocity: event.touches[0].clientX - state.x,
          yVelocity: event.touches[0].clientY - state.y,
          gesturing: true,
          type: event.type,
        };
      } else {
        return {
          ...state,
          time,
          timeInitial: time,
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
          xInitial: event.touches[0].clientX,
          yInitial: event.touches[0].clientY,
          xPrev: event.touches[0].clientX,
          yPrev: event.touches[0].clientY,
          xDelta: 0,
          yDelta: 0,
          gesturing: true,
          type: event.type,
        };
      }
    case TOUCH_END:
      return {
        ...state,
        time,
        duration: time - state.time,
        elapsed: time - state.timeInitial,
        gesturing: false,
        type: event.type,
      };
  }
  throw new Error(`Could not handle event ${event}`);
}

function shouldGesture(state) {
  if (!state.firstEvent) return false;
  if (!state.event) return false;
  if (!state.threshold) return true;
  const {firstEvent, event} = state;
  switch (state.orientation) {
    case VERTICAL: {
      const yDelta = event.touches[0].clientY - firstEvent.touches[0].clientY;
      return Math.abs(yDelta) > state.threshold;
    }
    case HORIZONTAL: {
      const xDelta = event.touches[0].clientX - firstEvent.touches[0].clientX;
      return Math.abs(xDelta) > state.threshold;
    }
    default: {
      const yDelta = event.touches[0].clientY - firstEvent.touches[0].clientY;
      const xDelta = event.touches[0].clientX - firstEvent.touches[0].clientX;
      return Math.max(Math.abs(xDelta), Math.abs(yDelta)) > state.threshold;
    }
  }
}

function shouldCancel(state) {
  if (!state.firstEvent) return false;
  if (!state.event) return false;
  if (!state.orientation) return false;
  const cancelThreshold = Math.max(0, state.cancelThreshold ?? 0);
  const yDelta = Math.abs(
    state.event.touches[0].clientY - state.firstEvent.touches[0].clientY,
  );
  const xDelta = Math.abs(
    state.event.touches[0].clientX - state.firstEvent.touches[0].clientX,
  );
  switch (state.orientation) {
    case VERTICAL: {
      return xDelta > yDelta && xDelta > cancelThreshold;
    }
    case HORIZONTAL: {
      return yDelta > xDelta && yDelta > cancelThreshold;
    }
  }
}

const WEBKIT_HACK_OPTIONS = {passive: false};

class WebkitHack {
  constructor() {
    // Do nothing when server side rendering or no touch support.
    if (typeof window !== 'undefined' && 'ontouchstart' in window) {
      // Adding a persistent event handler.
      // It can't be passive, otherwise we wouldn't
      // be able to preventDefault().
      window.addEventListener(
        TOUCH_MOVE,
        this.handleTouchMove,
        WEBKIT_HACK_OPTIONS,
      );
    }
  }

  shouldPreventDefault = false;

  destroy() {
    if (typeof window === 'undefined') return;
    window.removeEventListener(
      TOUCH_MOVE,
      this.handleTouchMove,
      WEBKIT_HACK_OPTIONS,
    );
  }

  handleTouchMove = event => {
    if (this.shouldPreventDefault && !event.defaultPrevented) {
      event.preventDefault();
    }
  };

  preventTouchMove() {
    this.shouldPreventDefault = true;
  }

  allowTouchMove() {
    this.shouldPreventDefault = false;
  }
}

const extractEvent = ({event}) => event;

function initEventSourceState(config) {
  const state = {
    ...config,
    firstEvent: null,
    gesturing: false,
    canceled: false,
    webkitHack: config.preventDefault ? new WebkitHack() : null,
  };
  return state;
}

function shouldPreventDefault(state) {
  return (
    state.event instanceof TouchEvent &&
    state.event.type === TOUCH_MOVE &&
    state.preventDefault &&
    !state.event.defaultPrevented &&
    typeof state.event.preventDefault === 'function'
  );
}

function updateEventSourceState(state, action) {
  state.event = action;
  switch (action.type) {
    case TOUCH_START: {
      if (state.firstEvent) return state;
      state.firstEvent = action;
      if (state.webkitHack) state.webkitHack.preventTouchMove();
      if (state.threshold) return state;
      state.gesturing = true;
      return state;
    }
    case TOUCH_MOVE: {
      if (!state.firstEvent) return state;
      if (state.canceled) return state;
      if (!state.gesturing) {
        state.gesturing = shouldGesture(state);
        if (!state.gesturing) {
          state.canceled = shouldCancel(state);
          return state;
        }
      }
      return state;
    }
    case TOUCH_END: {
      if (!state.firstEvent) return state;
      if (state.webkitHack) state.webkitHack.allowTouchMove();
      state.firstEvent = null;
      state.canceled = false;
      state.gesturing = false;
      return state;
    }
  }
}

function createEventSource(element, config) {
  // Make sure we have a DOM element to observe.
  ensureDOMInstance(element, Element);
  // Parse and extract the config (with defaults).
  const parsedConfig = parseConfig(config);
  const eventSource = merge(
    fromEvent(element, TOUCH_START),
    fromEvent(document, TOUCH_END),
    fromEvent(document, TOUCH_MOVE, {passive: parsedConfig.passive}),
  );

  let hasFirstEvent = false;
  const maybeGestureEvent = state => {
    const hadFirstEvent = hasFirstEvent;
    hasFirstEvent = Boolean(state.firstEvent);
    // If state has a 'firstEvent', or had a `firstEvent`
    // on the last update, we might be gesturing.
    return hasFirstEvent || hadFirstEvent;
  };

  return pipe(
    eventSource,
    scan(updateEventSourceState, initEventSourceState(parsedConfig)),
    filter(maybeGestureEvent),
  );
}

export function createSource(elementOrSource, config) {
  const eventSource =
    typeof elementOrSource === 'function'
      ? elementOrSource
      : createEventSource(elementOrSource, config);

  let wasGesturing = false;
  const isGesturing = sourceState => {
    if (sourceState.canceled) {
      return false;
    }
    if (shouldPreventDefault(sourceState)) {
      // eslint-disable-next-line no-unused-expressions,babel/no-unused-expressions
      sourceState.event?.preventDefault;
    }
    if (wasGesturing && !sourceState.gesturing) {
      // Let the 'end' event through.
      wasGesturing = sourceState.gesturing;
      return true;
    }
    wasGesturing = sourceState.gesturing;
    return sourceState.gesturing;
  };

  return share(
    pipe(
      eventSource,
      filter(isGesturing),
      map(extractEvent),
      scan(updateGestureState, DEFAULT_INITIAL_STATE),
    ),
  );
}

export function create(element, config) {
  const eventSource = share(createEventSource(element, config));
  return asObservable(createSource(eventSource), eventSource);
}

export default {create, createSource};
