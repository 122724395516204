import {ensureDOMInstance} from '../dom-utils';
import MovingAverage from '../moving-average';
import share from 'callbag-share';
import pipe from 'callbag-pipe';
import scan from 'callbag-scan';
import map from 'callbag-map';
import merge from 'callbag-merge';
import filter from 'callbag-filter';
import fromEvent from 'callbag-from-event';
import createSubject from 'callbag-subject';
import asObservable from './asObservable';
import {HORIZONTAL, VERTICAL} from './Orientation';
import {parseConfig} from './ObservableConfig';

export {HORIZONTAL, VERTICAL};

export const WHEEL = 'wheel';
export const GESTURE_END = 'gestureend';
export const UNBLOCK = 'unblock';
export const CANCEL_END = 'cancelend';

/**
 * How long to wait for additional intentional events
 * before ending a gesture.
 */
const GESTURE_END_TIMEOUT_MIN = 35;
const GESTURE_END_TIMEOUT_MAX = 140;
/**
 * The lower bound on the average intentional event delta.
 * When the average delta is smaller than this, incoming
 * wheel events won't be considered 'intentional'
 * unless they deviate significantly from the average.
 */
const VELOCITY_THRESHOLD = 0.1;
/**
 * How big the absolute difference between an event delta
 * and the average must be to be considered intentional.
 */
const VELOCITY_DEVIATION_THRESHOLD = 0.01;
/** How many pixels one delta{X,Y} unit is when `wheelMode` is 'line'. */
const LINE_HEIGHT = 40;
/** How many pixels one delta{X,Y} unit is when `wheelMode` is 'page'. */
const PAGE_HEIGHT = 800;
/** How many pixels one native wheelDelta{X,Y} 'spin' (probably) covers. */
const SPIN_FACTOR = 120;

const isGestureEndEvent = event => event?.type === GESTURE_END;

// Based on https://github.com/facebookarchive/fixed-data-table/blob/3a9bf3/src/vendor_upstream/dom/normalizeWheel.js
function normalizeWheel(event) {
  let {deltaX, deltaY} = event;
  const {deltaMode, timeStamp = Date.now()} = event;

  if ((deltaX || deltaY) && deltaMode) {
    if (deltaMode === 1) {
      // delta in LINE units
      deltaX *= LINE_HEIGHT;
      deltaY *= LINE_HEIGHT;
    } else {
      // delta in PAGE units
      deltaX *= PAGE_HEIGHT;
      deltaY *= PAGE_HEIGHT;
    }
  }

  let spinX = 0;
  let spinY = 0;

  if ('detail' in event) {
    spinY = event.detail;
  }
  if ('wheelDelta' in event) {
    spinY = -event.wheelDelta / SPIN_FACTOR;
  }
  if ('wheelDeltaY' in event) {
    spinY = -event.wheelDeltaY / SPIN_FACTOR;
  }
  if ('wheelDeltaX' in event) {
    spinX = -event.wheelDeltaX / SPIN_FACTOR;
  }

  // Fall-back if spin cannot be determined
  if (deltaX && !spinX) {
    spinX = deltaX < 1 ? -1 : 1;
  }

  if (deltaY && !spinY) {
    spinY = deltaY < 1 ? -1 : 1;
  }

  return {
    ...event,
    type: 'wheel',
    originalEvent: event,
    spinX,
    spinY,
    deltaX,
    deltaY,
    deltaMode,
    timeStamp,
  };
}

const INITIAL_GESTURE_STATE = {
  x: 0,
  y: 0,
  xSpin: 0,
  ySpin: 0,
  xDelta: 0,
  yDelta: 0,
  xInitial: 0,
  yInitial: 0,
  xPrev: 0,
  yPrev: 0,
  xVelocity: 0,
  yVelocity: 0,
  gesturing: false,
  type: null,
  time: Infinity,
  timeInitial: Infinity,
  duration: 0,
  elapsed: 0,
};

function updateGestureState(state, event) {
  const {timeStamp: time} =
    'originalEvent' in event ? event.originalEvent : event;
  switch (event.type) {
    case WHEEL:
      if (state.gesturing) {
        return {
          ...state,
          time,
          duration: time - state.time,
          elapsed: time - state.timeInitial,
          x: event.clientX || event.originalEvent.clientX || state.x,
          y: event.clientY || event.originalEvent.clientY || state.y,
          xPrev: state.x,
          yPrev: state.y,
          xDelta: state.xDelta - event.deltaX,
          yDelta: state.yDelta - event.deltaY,
          xSpin: state.xSpin + event.spinX,
          ySpin: state.ySpin + event.spinY,
          xVelocity: event.deltaX ? -event.deltaX : 0,
          yVelocity: event.deltaY ? -event.deltaY : 0,
          gesturing: true,
          type: event.type,
        };
      } else {
        return {
          ...state,
          time,
          timeInitial: time,
          x: event.clientX || event.originalEvent.clientX || 0,
          y: event.clientY || event.originalEvent.clientY || 0,
          xInitial: event.clientX || event.originalEvent.clientX || 0,
          yInitial: event.clientY || event.originalEvent.clientY || 0,
          xPrev: event.clientX || event.originalEvent.clientX || 0,
          yPrev: event.clientY || event.originalEvent.clientY || 0,
          xDelta: event.deltaX ? -event.deltaX : 0,
          yDelta: event.deltaY ? -event.deltaY : 0,
          xVelocity: event.deltaX ? -event.deltaX : 0,
          yVelocity: event.deltaY ? -event.deltaY : 0,
          xSpin: event.spinX,
          ySpin: event.spinY,
          gesturing: true,
          type: event.type,
        };
      }
    case GESTURE_END: {
      return {
        ...state,
        time,
        duration: time - state.time,
        elapsed: time - state.timeInitial,
        gesturing: false,
        type: event.type,
      };
    }
  }
  throw new Error(`Could not handle event ${event}`);
}

const extractEvent = ({event}) => event;

function initSourceState(config) {
  const state = {
    x: new MovingAverage({size: 6, weight: -1}),
    y: new MovingAverage({size: 6, weight: -1}),
    v: new MovingAverage({size: 6, weight: -1}),
    t: new MovingAverage({size: 6, weight: 0, round: true}),
    endTimeout: null,
    gesturing: false,
    intentional: false,
    blocked: false,
    canceled: false,
    lastTimestamp: null,
    ...config,
  };
  state.t.push(GESTURE_END_TIMEOUT_MAX);
  return state;
}

function shouldGesture(state) {
  if (!state.event) return false;
  if (!state.threshold) return true;
  switch (state.orientation) {
    case VERTICAL: {
      const yDelta = Math.abs(state.y.delta);
      return yDelta > state.threshold;
    }
    case HORIZONTAL: {
      const xDelta = Math.abs(state.x.delta);
      return xDelta > state.threshold;
    }
    default: {
      const yDelta = Math.abs(state.y.delta);
      const xDelta = Math.abs(state.x.delta);
      return Math.max(xDelta, yDelta) > state.threshold;
    }
  }
}

function shouldCancel(state) {
  if (!state.event) return false;
  if (!state.orientation) return false;
  const cancelThreshold = Math.max(0, state.cancelThreshold ?? 0);
  const xDelta = Math.abs(state.x.delta);
  const yDelta = Math.abs(state.y.delta);
  switch (state.orientation) {
    case VERTICAL: {
      return xDelta > yDelta && xDelta > cancelThreshold;
    }
    case HORIZONTAL: {
      return yDelta > xDelta && yDelta > cancelThreshold;
    }
  }
}

function shouldPreventDefault({event, preventDefault, passive, canceled}) {
  return (
    event?.originalEvent instanceof WheelEvent &&
    event.originalEvent.type === WHEEL &&
    preventDefault &&
    !passive &&
    !canceled &&
    !event.originalEvent.defaultPrevented &&
    typeof event.originalEvent.preventDefault === 'function'
  );
}

function updateSourceState(state, action) {
  const wasGesturing = state.gesturing;
  switch (action.type) {
    case CANCEL_END:
    case GESTURE_END:
    case UNBLOCK: {
      if (wasGesturing || state.canceled || state.blocked) {
        state.x.reset();
        state.y.reset();
        state.v.reset();
        state.t.reset();
        state.t.push(GESTURE_END_TIMEOUT_MAX);
        state.lastTimestamp = null;
        state.gesturing = false;
        state.intentional = false;
        state.blocked = wasGesturing;
        state.canceled = false;
      }
      break;
    }
    default: {
      state.event = action;
      state.v.push(
        Math.hypot(
          isNaN(state.x.peek()) ? 0 : action.spinX - state.x.peek(),
          isNaN(state.y.peek()) ? 0 : action.spinY - state.y.peek(),
        ),
      );
      state.x.push(action.spinX);
      state.y.push(action.spinY);

      if (state.lastTimestamp) {
        state.t.push(
          Math.min(
            GESTURE_END_TIMEOUT_MAX,
            Math.max(
              GESTURE_END_TIMEOUT_MIN,
              (action.timeStamp - state.lastTimestamp) * 2,
            ),
          ),
        );
      }
      state.lastTimestamp = action.timeStamp;

      if (state.canceled || state.blocked) return state;

      state.gesturing = state.gesturing || shouldGesture(state);
      state.intentional =
        (state.gesturing && !wasGesturing) ||
        !state.v.rolling ||
        state.v.deviation > VELOCITY_DEVIATION_THRESHOLD ||
        state.v.value > VELOCITY_THRESHOLD;
      state.canceled = state.gesturing ? false : shouldCancel(state);
      break;
    }
  }

  return state;
}

function createEventSource(actions, element, config) {
  // Make sure we have a DOM element to observe.
  ensureDOMInstance(element, Element);
  // Parse and extract the config (with defaults).
  const parsedConfig = parseConfig({
    ...config,
    velocityThreshold: config?.velocityThreshold ?? VELOCITY_THRESHOLD,
    velocityDeviationThreshold:
      config?.velocityDeviationThreshold ?? VELOCITY_DEVIATION_THRESHOLD,
  });
  const eventSource = pipe(
    fromEvent(element, WHEEL, {passive: parsedConfig.passive}),
    map(normalizeWheel),
  );
  return pipe(
    merge(eventSource, actions),
    scan(updateSourceState, initSourceState(parsedConfig)),
  );
}

export function createSource(elementOrSource, actionsOrConfig) {
  let source;
  let actions;
  if (typeof elementOrSource === 'function') {
    source = elementOrSource;
    if (typeof actionsOrConfig !== 'function') {
      throw new Error(
        'an actions source is required when using an event source!',
      );
    }
    actions = actionsOrConfig;
  } else {
    actions = createSubject();
    source = createEventSource(actions, elementOrSource, actionsOrConfig);
  }

  const dispatch = action => {
    actions(1, action);
  };

  let endTimeout = null;
  let lastEvent;

  const dispatchEndEvent = sourceState => () => {
    if (endTimeout !== null) clearTimeout(endTimeout);
    endTimeout = null;
    if (sourceState.gesturing) {
      dispatch(new WheelEvent(GESTURE_END, lastEvent));
    } else if (sourceState.blocked) {
      dispatch(new WheelEvent(UNBLOCK, lastEvent));
    } else if (sourceState.canceled) {
      dispatch(new WheelEvent(CANCEL_END, lastEvent));
    }
  };

  const scheduleGestureEnd = sourceState => {
    if (endTimeout !== null) clearTimeout(endTimeout);
    endTimeout = setTimeout(dispatchEndEvent(sourceState), sourceState.t.value);
  };

  const isGesturing = sourceState => {
    if (!sourceState.event || isGestureEndEvent(sourceState.event)) {
      return false;
    }

    lastEvent = sourceState.event;

    if (shouldPreventDefault(sourceState)) {
      // eslint-disable-next-line no-unused-expressions,babel/no-unused-expressions
      sourceState.event?.originalEvent.preventDefault();
    }

    if (sourceState.canceled || sourceState.blocked) {
      // Debounce the cancel end timeout.
      scheduleGestureEnd(sourceState);
      return false;
    }

    if (sourceState.gesturing && sourceState.intentional) {
      // Debounce the gesture end timeout.
      scheduleGestureEnd(sourceState);
    }

    return sourceState.gesturing;
  };

  return share(
    pipe(
      merge(
        pipe(
          source,
          filter(isGesturing),
          map(extractEvent),
        ),
        pipe(
          actions,
          filter(isGestureEndEvent),
        ),
      ),
      scan(updateGestureState, INITIAL_GESTURE_STATE),
    ),
  );
}

export function create(element, config) {
  const actions = createSubject();
  const eventSource = share(createEventSource(actions, element, config));
  return asObservable(createSource(eventSource, actions), eventSource);
}

export default {create, createSource};
