import React from 'react';

export default () => (
  <svg width="85" height="38" viewBox="0 0 85 38">
    <g fill="none" fillRule="evenodd">
      <path d="M118.43 18.795h1.015-1.015z" />
      <g fill="#FF7C1C" fillRule="nonzero">
        <path d="M77.9599921 11.6453708c-.786081 0-1.572162.1626374-2.2709007.4065936-.4367116.1626375-.8734233.4065936-1.310135.6505498-1.7468466 1.0571434-2.882297 2.8461554-2.882297 4.8791235 0 .8945059.262027 1.7076932.6113964 2.4395617v6.5054979H12.715269v-6.5054979c.3493693-.7318685.6113964-1.5450558.6113964-2.4395617 0-2.1142869-1.2227927-3.9032988-3.05698172-4.9604422-.34936933-.1626375-.69873867-.4065936-1.13545034-.4879124-.69873866-.2439561-1.48481966-.4065936-2.27090066-.4065936C3.282297 11.7266895.4 14.3288887.4 17.5816377c0 2.1142868 1.310135 3.9846175 3.144324 5.0417609v6.180223c0 1.5450558 4.27977434 5.9362669 6.20130568 6.5054979C9.83297201 36.7728566 10.18234135 37.83 10.619053 37.83h2.5329277c.3493693 0 .6987387-.8131872.786081-2.0329681h56.8598591c.0873423 1.1384621.4367116 2.0329681.786081 2.0329681h2.5329276c.4367117 0 .786081-1.0571434.8734234-2.4395617 2.0088736-.5692311 6.3759903-5.0417609 6.3759903-6.5868167v-6.180223c1.834189-1.0571434 3.144324-2.8461554 3.144324-5.0417609-.0873423-3.3340677-2.9696393-5.9362669-6.550675-5.9362669z" />
        <path d="M13.5886924 20.5091117h57.6459401v5.0417609H13.5886924zM14.2000887 17.5003189c0 .7318686-.1746847 1.4637371-.4367117 2.1956056h57.3839131c-.262027-.7318685-.4367117-1.463737-.4367117-2.1956056 0-2.0329681 1.048108-3.9846174 2.882297-5.285717V4.24536689c0-1.87033066-1.1354503-3.41538642-2.358243-3.41538642H13.50135c-1.310135 0-2.358243 1.54505576-2.358243 3.41538642v7.88791621c1.9215313 1.2197809 3.0569817 3.1714303 3.0569817 5.3670358z" />
      </g>
    </g>
  </svg>
);
