import React from 'react';

export default () => (
  <svg width="58" height="67" viewBox="0 0 58 67">
    <g fill="#FF7C1C" fillRule="nonzero" transform="translate(0 .6)">
      <ellipse cx="22.1666667" cy="31.2" rx="10.5" ry="10.8" />
      <path d="M30.7397028 44.4c-2.6410806 1.7316672-5.782175 2.7401562-9.1563695 2.7401562-3.3490139 0-6.468525-.99382-9.097375-2.702017C6.26923889 45.9365705 0 51.0882988 0 57.1693039L1.13672222 66H42.1162778l1.0503889-8.8306961c0-6.1330798-6.209525-11.3214805-12.4269639-12.7693039zM43.75 0c-7.4101249 0-13.4166667 5.91023947-13.4166667 13.2 0 7.2897605 6.007251 13.2 13.4166667 13.2s13.4166667-5.9095417 13.4166667-13.2c0-7.29045832-6.007251-13.2-13.4166667-13.2zm0 3.44347826c1.2348083 0 2.2361111 1.0279646 2.2361111 2.29565217 0 1.26768758-1.0013028 2.29565218-2.2361111 2.29565218s-2.2361111-1.0279646-2.2361111-2.29565218C41.5145485 4.47076568 42.5151917 3.44347826 43.75 3.44347826zm2.4409935 18.26534724c-3.3684816.6325827-5.5626917-1.8289598-5.5626917-4.8664359v-5.1699138l-2.4685796-.7155333V9.89814202l7.5928766-.71553332v7.8027527c0 .2009698.0238583.4019395.0626279.5995373.2221796 1.1282632 1.7886204 1.5349235 3.5250511.6217924-.3019555 1.4829951-1.5530206 3.160216-3.1492843 3.5021344z" />
    </g>
  </svg>
);
