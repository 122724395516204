import React from 'react';

export default () => (
    <svg width="266" height="111" viewBox="0 0 200 85">
        <g>
            <path
                className="st0"
                d="M217.2 380.7c0 9.9-4.4 12.7-11.9 12.7s-11.9-2.8-11.9-12.7V273.4h-23v110.9c0 21.6 13.5 29.3 34.5 29.3h.8c21 0 34.5-7.9 34.5-29.3V273.4h-23v107.3zM284.5 273.4h-23V412H320v-20.6h-35.5zM388.3 339.9c11.1-1.4 15.4-11.9 15.4-21.8v-17.4c0-17.4-12.3-27.3-33.3-27.3h-36.2V412H370c21 0 34.5-9.9 34.5-27.3v-22.8c.1-10.7-4.1-20-16.2-22zm-31.1-46.7h12.9c6.5 0 10.7 3.4 10.7 10.7v16c0 7.3-4.2 10.7-10.7 10.7h-12.9v-37.4zm24.4 88.3c0 7.3-4.2 10.7-10.7 10.7h-13.7V350h13.7c6.5 0 10.7 3.4 10.7 10.7v20.8zM446.5 351.8h31.7v-20.2h-31.7v-38h37.9v-20.2h-60.8V412H485v-20.2h-38.5zM573.9 327.5v-24.8c0-21-13.9-29.3-34.9-29.3h-35.8V412h23v-55.3h11.5l17.6 55.3h23.4l-19.8-58.8c9.5-4.1 15-12.5 15-25.7zm-23-3.2c0 9.9-5.1 12.7-12.7 12.7h-12.1v-43.4h12.1c7.5 0 12.7 2.8 12.7 12.7v18zM665.2 327.5v-24.8c0-21-13.9-29.3-34.9-29.3h-35.8V412h23v-55.3H629l17.6 55.3H670l-19.8-58.8c9.5-4.1 15-12.5 15-25.7zm-23-3.2c0 9.9-5.1 12.7-12.7 12.7h-12.1v-43.4h12.1c7.5 0 12.7 2.8 12.7 12.7v18zM729.4 273.4l-15.5 64.4-15.4-64.4h-24.2l27.9 89.3V412h23v-49.3l27.9-89.3zM99.8 250.6c5 0 8.5-1.6 10-4.3V263c0 7.6-2.4 10.1-8.7 10.1-6.3 0-8.7-2.4-8.7-10.1v-5.1h-5.1v6.2c0 9 3.8 13.5 13.7 13.5h.2c10 0 13.7-4.5 13.7-13.5v-40.2c0-9-4-13.5-14-13.5h-.2c-10 0-14 4.5-14 13.5v14.7c-.1 8.1 5.5 12 13.1 12zM91.7 225c0-7.6 2.7-10.1 9-10.1s9 2.4 9 10.1v12.3c0 6.1-3.9 8.6-9 8.6-5.4 0-9-2.6-9-8.6V225z"
                style={{
                fill: "#fff",
                }}
                transform="matrix(.27396 0 0 .27396 -10.239 -36.073)"
            />
            <path
                className="st0"
                d="M139 210.4h-2.9c-8.8 0-13 5.4-13 13.9v8.1c0 4.1.9 9.7 7.1 10.3-6.5.9-7.1 7.8-7.1 11.4v9.7c0 4.2 1 7.7 3.2 10.1L99.5 354 74 276.9h8.2l-1.5-4.6H56.1v-8.2c0-5.8 3.4-9.2 10.3-14.5 7-5.4 12.8-9.6 12.8-15.8v-10c0-9-4.7-13.5-13.9-13.5h-.2c-9.2 0-13.9 4.5-13.9 13.5v9h5V225c0-6.8 2.7-10.1 8.8-10.1s9.1 3.3 9.1 10.1v7.9c0 4.9-3.7 7.7-10.1 12.5-7.3 5.4-13 10.1-13 18.5V412h21.4v-84.8l20.8 64.6h12.9l20.8-64.6V412H149V273.5c2-2.4 2.9-5.7 2.9-9.8V254c0-3.6-.7-10.4-7.1-11.4 6.2-.7 7.1-6.2 7.1-10.3v-8.1c.1-8.4-4-13.8-12.9-13.8zm7.9 42.9v9.3c0 7.5-3.2 10.4-8.5 10.4h-1.8c-5.3 0-8.5-2.9-8.5-10.4v-9.3c0-5.9 3.2-8.2 8.5-8.2h1.8c5.3 0 8.5 2.3 8.5 8.2zm0-20.8c0 5.9-3.2 8.2-8.5 8.2h-1.8c-5.3 0-8.5-2.3-8.5-8.2v-7c0-7.5 3.2-10.4 8.5-10.4h1.8c5.3 0 8.5 2.9 8.5 10.4v7z"
                style={{
                fill: "#fff",
                }}
                transform="matrix(.27396 0 0 .27396 -10.239 -36.073)"
            />
        </g>
    </svg>
);
