// @flow

import React, {useState, useCallback, Fragment, type Element} from 'react';
import {Row, InlineBlock, Col} from 'jsxstyle';
import Slider from './Slider';

import Arrow from '@components/icons/Arrow';
import {BLUE, LIGHT_GREY} from '@styles/colors';
import SectionTitle from '@components/SectionTitle';
import Image from '@components/Image';
import {ORANGE, WHITE} from '@styles/colors';
import FetnerEquip from '@components/icons/FetnerEquip';

type Props = {
  data: Object[],
  imageLeft: boolean,
  eyebrow: string,
  headline: string,
  copy: string,
  list: string[],
  fetnerLogo: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

function CarouselButton({
  direction,
  onClick,
}: {
  direction?: string,
  onClick: (index: number) => void,
}): Element<*> {
  return (
    <Row
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      width="50px"
      height="50px"
      opacity="0.9"
      background={WHITE}
      position="absolute"
      zIndex="100"
      left={direction === 'next' ? 'auto' : '0'}
      right={direction === 'next' ? '0' : 'auto'}
      top="80%"
      mediaQueries={mediaQueries}
      smTop="auto"
      props={{onClick}}
    >
      <Arrow transform={direction === 'next' ? 'none' : 'rotate(180deg)'} />
    </Row>
  );
}

function renderButtons(
  numberOfSlides,
  activeSlide: number,
  setPrevIndex: (index: number) => void,
  setNextIndex: (index: number) => void,
): Element<*> {
  return (
    <Fragment>
      {activeSlide > 0 && <CarouselButton onClick={setPrevIndex} />}
      {!(activeSlide >= numberOfSlides - 1) && (
        <CarouselButton onClick={setNextIndex} direction="next" />
      )}
    </Fragment>
  );
}

export default function AmenitiesCarousel({
  data,
  eyebrow,
  headline,
  copy,
  imageLeft,
  list,
  fetnerLogo,
}: Props) {
  const [state, setState] = useState({index: 0});
  const {index} = state;
  const setIndex = useCallback(i => setState({...state, index: i}), [state]);
  const setPrevIndex = useCallback(
    () => setState(prev => ({...state, index: prev.index - 1})),
    [state],
  );
  const setNextIndex = useCallback(
    () => setState(prev => ({...state, index: prev.index + 1})),
    [state],
  );

  return (
    <Row
      alignItems="center"
      justifyContent="center"
      flexDirection={imageLeft ? 'row-reverse' : 'row'}
      height="600px"
      background={LIGHT_GREY}
      margin="80px auto"
      alignSelf="center"
      maxWidth="1360px"
      mediaQueries={mediaQueries}
      smFlexDirection={'column-reverse !important'}
      smHeight="100%"
    >
      <SectionTitle
        eyebrow={eyebrow}
        headline={headline}
        copy={copy}
        alignItems="flex-start"
        textAlign="left"
        dividerColor={ORANGE}
        mediaQueries={mediaQueries}
        height="100%"
        smHeight="50%"
      >
        <ul
          style={{
            listStyle: 'outside disc',
            color: BLUE,
            width: '100%',
            margin: '0 0 30px 1rem',
          }}
        >
          {list &&
            list.map(item => {
              return (
                <li style={{margin: '10px 0'}} key={item}>
                  {item}
                </li>
              );
            })}
        </ul>
        <Row justifyContent="space-between" width="100%" alignItems="center">
          <InlineBlock
            component="p"
            color={ORANGE}
            fontWeight="bold"
            textAlign="left"
          >
            {index + 1}/{data.length}
          </InlineBlock>
          {fetnerLogo && <FetnerEquip />}
        </Row>
      </SectionTitle>
      <Col
        height="100%"
        width="50%"
        mediaQueries={mediaQueries}
        position="relative"
        smWidth="100%"
        justifyContent="center"
      >
        {data.length > 0 &&
          renderButtons(data.length, index, setPrevIndex, setNextIndex)}
        <Slider
          enabled
          index={index}
          onIndexChange={setIndex}
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'flex-start',
            overflow: 'hidden',
          }}
          slideStyle={{minWidth: '100%', alignItems: 'flex-start'}}
        >
          {data.map(slide => {
            return (
              <Image
                key={slide.image.url}
                imageSrc={slide.image.url}
                alt={headline}
                width="100%"
                imgMaxHeight="600px"
                mediaQueries={mediaQueries}
                smMaxWidth="100%"
                smWidth="100%"
              />
            );
          })}
        </Slider>
      </Col>
    </Row>
  );
}
