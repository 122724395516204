import {ensureDOMInstance} from '../dom-utils';
import share from 'callbag-share';
import pipe from 'callbag-pipe';
import map from 'callbag-map';
import scan from 'callbag-scan';
import merge from 'callbag-merge';
import filter from 'callbag-filter';
import fromEvent from 'callbag-from-event';
import asObservable from './asObservable';
import {HORIZONTAL, VERTICAL} from './Orientation';
import {parseConfig} from './ObservableConfig';

export {HORIZONTAL, VERTICAL};

export const MOUSE_DOWN = 'mousedown';
export const MOUSE_MOVE = 'mousemove';
export const MOUSE_UP = 'mouseup';

const DEFAULT_INITIAL_STATE = {
  x: 0,
  y: 0,
  xDelta: 0,
  yDelta: 0,
  xInitial: 0,
  yInitial: 0,
  xPrev: 0,
  yPrev: 0,
  xVelocity: 0,
  yVelocity: 0,
  gesturing: false,
  type: null,
  time: Infinity,
  timeInitial: Infinity,
  duration: 0,
  elapsed: 0,
};

function updateGestureState(state, event) {
  const {timeStamp: time} = event;
  switch (event.type) {
    case MOUSE_DOWN:
    case MOUSE_MOVE:
      if (state.gesturing) {
        return {
          ...state,
          time,
          duration: time - state.time,
          elapsed: time - state.timeInitial,
          x: event.clientX,
          y: event.clientY,
          xPrev: state.x,
          yPrev: state.y,
          xDelta: event.clientX - state.xInitial,
          yDelta: event.clientY - state.yInitial,
          xVelocity: event.clientX - state.x,
          yVelocity: event.clientY - state.y,
          gesturing: true,
          type: event.type,
        };
      } else {
        return {
          ...state,
          time,
          timeInitial: time,
          x: event.clientX,
          y: event.clientY,
          xInitial: event.clientX,
          yInitial: event.clientY,
          xPrev: event.clientX,
          yPrev: event.clientY,
          xDelta: 0,
          yDelta: 0,
          gesturing: true,
          type: event.type,
        };
      }
    case MOUSE_UP:
      return {
        ...state,
        time,
        duration: time - state.time,
        elapsed: time - state.timeInitial,
        gesturing: false,
        type: event.type,
      };
  }
  throw new Error(`Could not handle event ${event}`);
}

function shouldGesture(state) {
  if (!state.firstEvent) return false;
  if (!state.event) return false;
  if (!state.threshold) return true;
  switch (state.orientation) {
    case VERTICAL: {
      const yDelta = Math.abs(state.event.clientY - state.firstEvent.clientY);
      return yDelta > state.threshold;
    }
    case HORIZONTAL: {
      const xDelta = Math.abs(state.event.clientX - state.firstEvent.clientX);
      return xDelta > state.threshold;
    }
    default: {
      const yDelta = Math.abs(state.event.clientY - state.firstEvent.clientY);
      const xDelta = Math.abs(state.event.clientX - state.firstEvent.clientX);
      return Math.max(xDelta, yDelta) > state.threshold;
    }
  }
}

function shouldCancel(state): boolean {
  if (!state.firstEvent) return false;
  if (!state.event) return false;
  if (!state.orientation) return false;
  const cancelThreshold = Math.max(0, state.cancelThreshold ?? 0);
  const xDelta = Math.abs(state.event.clientX - state.firstEvent.clientX);
  const yDelta = Math.abs(state.event.clientY - state.firstEvent.clientY);
  switch (state.orientation) {
    case VERTICAL: {
      return xDelta > yDelta && xDelta > cancelThreshold;
    }
    case HORIZONTAL: {
      return yDelta > xDelta && yDelta > cancelThreshold;
    }
  }
}

const CLICK = 'click';

class ClickHack {
  clickTimeout = null;
  clickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof window === 'undefined') return;
    window.removeEventListener(CLICK, this.clickHandler, true);
  };

  preventNextClick(): void {
    if (typeof window === 'undefined') return;
    window.addEventListener(CLICK, this.clickHandler, true);
    this.clickTimeout = setTimeout(this.destroy.bind(this), 0);
  }

  destroy(): void {
    if (this.clickTimeout !== null) {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
    }
    if (typeof window === 'undefined') return;
    window.removeEventListener(CLICK, this.clickHandler, true);
  }
}
const extractEvent = ({event}) => event;

function initEventSourceState(config) {
  return {
    ...config,
    firstEvent: null,
    gesturing: false,
    canceled: false,
    clickHack: config.preventDefault ? new ClickHack() : null,
  };
}

function shouldPreventDefault(state) {
  return (
    state.event instanceof MouseEvent &&
    state.event.type === MOUSE_MOVE &&
    state.preventDefault &&
    !state.event.defaultPrevented &&
    typeof state.event.preventDefault === 'function'
  );
}

function updateEventSourceState(state, action) {
  state.event = action;

  switch (action.type) {
    case MOUSE_DOWN: {
      if (state.firstEvent) return state;
      state.firstEvent = action;
      if (state.threshold) return state;
      state.gesturing = true;
      return state;
    }
    case MOUSE_MOVE: {
      if (!state.firstEvent) return state;
      if (state.canceled) return state;
      if (!state.gesturing) {
        state.gesturing = shouldGesture(state);
        if (!state.gesturing) {
          state.canceled = shouldCancel(state);
          return state;
        }
      }
      return state;
    }
    case MOUSE_UP: {
      if (!state.firstEvent) return state;
      if (state.gesturing && state.clickHack) {
        state.clickHack.preventNextClick();
      }
      state.firstEvent = null;
      state.canceled = false;
      state.gesturing = false;
      return state;
    }
  }
}

function createEventSource(element, config) {
  // Make sure we have a DOM element to observe.
  ensureDOMInstance(element, Element);
  // Parse and extract the config (with defaults).
  const parsedConfig = parseConfig(config);
  const eventSource = merge(
    fromEvent(element, MOUSE_DOWN),
    fromEvent(document, MOUSE_UP),
    fromEvent(document, MOUSE_MOVE, {passive: parsedConfig.passive}),
  );

  let hasFirstEvent = false;
  const maybeGestureEvent = state => {
    const hadFirstEvent = hasFirstEvent;
    hasFirstEvent = Boolean(state.firstEvent);
    // If state has a 'firstEvent', or had a `firstEvent`
    // on the last update, we might be gesturing.
    return hasFirstEvent || hadFirstEvent;
  };

  return pipe(
    eventSource,
    scan(updateEventSourceState, initEventSourceState(parsedConfig)),
    filter(maybeGestureEvent),
  );
}

export function createSource(elementOrSource, config) {
  const eventSource =
    typeof elementOrSource === 'function'
      ? elementOrSource
      : createEventSource(elementOrSource, config);

  let wasGesturing = false;
  const isGesturing = sourceState => {
    if (sourceState.canceled) {
      return false;
    }
    if (shouldPreventDefault(sourceState)) {
      // eslint-disable-next-line no-unused-expressions,babel/no-unused-expressions
      sourceState.event?.preventDefault();
    }
    if (wasGesturing && !sourceState.gesturing) {
      // Let the 'end' event through.
      wasGesturing = sourceState.gesturing;
      return true;
    }
    wasGesturing = sourceState.gesturing;
    return sourceState.gesturing;
  };

  return share(
    pipe(
      eventSource,
      filter(isGesturing),
      map(extractEvent),
      scan(updateGestureState, DEFAULT_INITIAL_STATE),
    ),
  );
}

export const create = (element, config) => {
  const eventSource = share(createEventSource(element, config));
  return asObservable(createSource(eventSource), eventSource);
};

export default {create, createSource};
