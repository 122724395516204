import React from 'react';

export default () => (
  <svg width="78" height="62" viewBox="0 0 78 62">
    <g fill="#FF7C1C" fillRule="nonzero">
      <path d="M30.2029846 26.16662c-1.8023384-.58652-9.5277538-3.10202-13.3607384-4.38428C14.8986154 26.78668 4.6308 53.86292 8.16913846 58.51896c3.90104614 5.13506 17.91310774-.88408 21.99249234 0 4.0802461.88666 3.5771077-1.9436 3.5771077-1.9436l-3.5357539-30.40874zM40.4776923 10.95924c-1.0700308-1.45942-7.1576615-1.15068-7.1576615-1.15068s-1.5447385-3.30842-2.5398154-3.92074c-1.1630769-.71294-4.4119385-.58652-4.4119385-.58652s.3024-4.79106-1.1622154-5.14796c-2.7948307-.68198-6.5123692 14.19688-7.6814769 19.20638 3.1463385 1.27366 9.0384 3.54492 12.3639385 4.0979l-.0327385-.28208s.1154462-2.41918 1.7325539-2.99624c1.0872615-.38786 6.4658461-1.48952 7.9683692-2.99624 1.1088-1.11456 1.8540308-4.95446.9209846-6.22382z" />
      <path d="M8.11348596 57.759283c-5.51678084-4.3875559-5.56746101-14.4848522-6.9775139-11.4346977-1.31850163 2.852334-.02942719 9.7354708 2.43510004 12.7577276 2.26425884 2.7762492 6.93337311 2.6435235 7.1189279.9028727" />
      <g>
        <path d="M46.30636324 35.2441147c2.40521514-.7369064 6.15020756-2.0862951 9.24040866-4.094606-.1199157-1.6332861.3985686-3.4549608.6185578-4.5660159.3459438-1.7612151-.1156021-2.76712299-1.0412821-5.28539771-.1665017-.45300905-.9205038-5.48079622-2.4267827-5.63676452-1.4855741-.15333963-3.4689276 4.93140226-3.4689276 4.93140226s-4.27900541 0-4.79058811 1.93821283c-.61510703 2.32813354-2.97977514 3.59428074-3.07208433 3.93425664-.3554335 1.3108347.27002595 4.8709426 2.32670919 5.414203 1.25436973.3320898 2.16279568 1.9890339 2.61398919 3.3647094zM72.0897383 53.4789629c2.1505537-.8609224 4.3185974-5.1759764 1.5280201-6.8464473-2.1812689-1.3049954-2.4222835-1.7019423-3.0378244-2.7146325-1.480766-2.4283811.4586213-9.2188539 4.1934807-10.0931749 2.7760027-.648606 2.9563302 1.8947945 1.4313493 2.7250102-3.6212877 2.1473184-3.9247234 5.7518384-1.4599589 7.5091284 1.3932031.9936644 2.3754676 1.7019422 2.9823389 3.9167157.8117817 2.957412-1.451495 7.5690807-5.8907404 9.3778676-.3953353 1.0585555-1.109567 1.9556767-2.2077355 2.6274325-6.8317427 2.9020619-17.7354422-.1682354-19.20031134.1051472-1.46400649.2690015-2.70457298.1691117-3.33607135-.8911223-.4140973-.693095.14407135-1.2337267.0974854-2.1029433-.0336454-.5861955.61338162-14.2728521-.51934703-19.6931887 2.77186379-.770203 7.76863782-3.4584657 9.43106592-4.3749985.1363071.2216853.2959071.4302272.4943287.6159872 5.4162797 2.1576906 14.3110164 12.4372452 15.4939199 19.8392187z" />
      </g>
    </g>
  </svg>
);
