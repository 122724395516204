import React from 'react';

export default () => (
  <svg
      height="62"
      style={{maxWidth: 50 + "px"}} 
      viewBox="312.092 138.407 36.984 42.984"
    >
      <path
        d="M346.205 138.407h-31.226a2.884 2.884 0 00-2.887 2.876v37.23a2.884 2.884 0 002.887 2.877h31.21a2.884 2.884 0 002.886-2.876v-37.23c.017-1.588-1.277-2.877-2.87-2.877zm-31.226 1.653h31.21c.68 0 1.227.545 1.227 1.223v6.2h-33.665v-6.2c0-.678.548-1.223 1.228-1.223zm31.226 39.694h-31.226c-.68 0-1.228-.546-1.228-1.224v-29.394h33.682v29.378c0 .677-.548 1.24-1.228 1.24z"
        fill="#FF7C1C"
        strokeMiterlimit={50}
        vectorEffect="non-scaling-stroke"
        strokeWidth="3px"
        stroke="#ff7c1c"
      />
      <path
        d="M330.415 153.5c-6.128 0-11.115 4.969-11.115 11.074 0 6.105 4.987 11.074 11.115 11.074 6.126 0 11.113-4.969 11.113-11.074 0-6.105-4.987-11.074-11.113-11.074zm0 20.674c-5.314 0-9.635-4.306-9.635-9.6 0-5.293 4.321-9.6 9.635-9.6 5.312 0 9.633 4.307 9.633 9.6 0 5.294-4.321 9.6-9.633 9.6z"
        fill="#FF7C1C"
        strokeWidth="1px"
        stroke="#ff7c1c"
      />
      <path
        d="M330.415 158.749c-3.133 0-5.68 2.613-5.68 5.825 0 3.211 2.547 5.824 5.68 5.824 3.131 0 5.679-2.613 5.679-5.824 0-3.212-2.548-5.825-5.679-5.825zm0 10.579c-2.559 0-4.636-2.131-4.636-4.754 0-2.624 2.077-4.754 4.636-4.754 2.557 0 4.635 2.13 4.635 4.754 0 2.623-2.078 4.754-4.635 4.754z"
        fill="#FF7C1C"
        strokeWidth="1px"
        stroke="#ff7c1c"
      />
      <path d="M322.395 142.953h-5.359v1.653h5.36v-1.653z" fill="#FF7C1C" />
      <path
        d="M337.959 142.4H336.3v2.761h1.659V142.4zM343.052 142.4h-1.659v2.761h1.659V142.4z"
        fill="#FF7C1C"
        strokeWidth="1px"
        stroke="#ff7c1c"
      />
    </svg>
);
