// @flow

import {
  getFloorFromUnit,
  getPropertyNameFromUnit,
  flatten,
  makeUniqueIds,
} from '@util';

import type {PropertyType} from '@types-local';

export default function getAvailableUnitsFromProperty(
  property: PropertyType,
): Object[] | null {
  if (!property || property.soldout) return null;
  const {floorplans} = property;
  const floorplanUnits = floorplans.filter(
    floorplan => floorplan.units && floorplan.units.length > 0,
  );
  const availableUnits = floorplanUnits
    .map(unit => {
      const {units} = unit;
      const filteredUnits = units
        .filter(u => u.available)
        .map(availableUnit => {
          const mergedUnitFloorplan = Object.assign({}, availableUnit, unit);
          mergedUnitFloorplan.propertyName = property.name;
          delete mergedUnitFloorplan.units;
          const name = getPropertyNameFromUnit(mergedUnitFloorplan);
          if (name) {
            const floor = getFloorFromUnit(mergedUnitFloorplan);
            mergedUnitFloorplan.name = name;
            mergedUnitFloorplan.floor = floor;
            return mergedUnitFloorplan;
          } else return null;
        });
      return filteredUnits;
    })
    .filter(ar => ar.length > 0)
    .reduce(flatten, [])
    // $FlowFixMe
    .reduce(makeUniqueIds, []);
  return availableUnits;
}
