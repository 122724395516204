import useRefCallback from '../use-ref-callback';
import useMouseGesture from './useMouseGesture';
import useWheelGesture from './useWheelGesture';
import useTouchGesture from './useTouchGesture';
import useKeyboardGesture from './useKeyboardGesture';

function useGesture(handlerOrProvidedRef, handlerOrConfig, maybeConfig) {
  let providedRef;
  let gestureHandler;
  let gestureConfig;

  if ('current' in handlerOrProvidedRef) {
    providedRef = handlerOrProvidedRef;
    gestureHandler = handlerOrConfig;
    gestureConfig = maybeConfig;
  } else {
    gestureHandler = handlerOrProvidedRef;
    gestureConfig = handlerOrConfig;
  }

  const [ref, setRef] = useRefCallback(null);

  if (providedRef) {
    setRef(providedRef.current);
  }

  // useMouseGesture(ref, gestureHandler, gestureConfig);
  useWheelGesture(ref, gestureHandler, gestureConfig);
  useTouchGesture(ref, gestureHandler, gestureConfig);
  useKeyboardGesture(ref, gestureHandler, gestureConfig);

  if (!providedRef) {
    return setRef;
  }
}

export default useGesture;
