import {useMemo, useReducer, useRef, useLayoutEffect} from 'react';
import {
  useConfigFor,
  useKeyboardGesture,
  useMouseGesture,
  useTouchGesture,
  useWheelGesture,
} from '../gesture-catcher';
import usePageGesture, {
  VERTICAL,
  HORIZONTAL,
  FIRST,
  PREVIOUS,
  NEXT,
  LAST,
  CANCELED,
  UNKNOWN,
} from './usePageGesture';

function pageGestureStateReducer(state, action) {
  switch (action.type) {
    case NEXT:
    case PREVIOUS:
    case FIRST:
    case LAST:
    case CANCELED:
      if (state && 'gesturing' in state && !state.gesturing) {
        return {...state, action: action.type};
      } else {
        return state;
      }
    case 'gesture':
      return action.payload;
    case 'gestureend':
      return {...action.payload, action: UNKNOWN};
    default:
      return state;
  }
}

function usePageGestureConfig(props) {
  const {threshold, orientation} = props;
  return useMemo(() => {
    let config = null;
    if (orientation === VERTICAL || orientation === HORIZONTAL) {
      config = {...(config || {}), orientation};
    }
    if (typeof threshold === 'number') {
      config = {...(config || {}), threshold};
    }
    return config;
  }, [threshold, orientation]);
}

function PageGesture(props) {
  const gestureRef = useRef(null);
  const [state, dispatch] = useReducer(pageGestureStateReducer, null);
  const {onStart, onMove, onEnd, onFirst, onLast, onNext, onPrevious} = props;

  const paginationConfig = usePageGestureConfig(props);

  const paginationHandler = useMemo(
    () => ({
      onFirst() {
        if (typeof onFirst === 'function') onFirst();
        dispatch({type: FIRST});
      },
      onLast() {
        if (typeof onLast === 'function') onLast();
        dispatch({type: LAST});
      },
      onNext() {
        if (typeof onNext === 'function') onNext();
        dispatch({type: NEXT});
      },
      onPrevious() {
        if (typeof onPrevious === 'function') onPrevious();
        dispatch({type: PREVIOUS});
      },
    }),
    [onFirst, onLast, onNext, onPrevious],
  );

  const updatePagination = usePageGesture(paginationHandler, paginationConfig);

  const gestureHandler = useMemo(
    () => ({
      onStart(payload) {
        if (typeof onStart === 'function') onStart(payload);
        dispatch({type: 'gesture', payload});
        updatePagination(payload);
      },
      onMove(payload) {
        if (typeof onMove === 'function') onMove(payload);
        dispatch({type: 'gesture', payload});
        updatePagination(payload);
      },
      onEnd(payload) {
        if (typeof onEnd === 'function') onEnd(payload);
        dispatch({type: 'gestureend', payload});
        updatePagination(payload);
      },
    }),
    [updatePagination, onStart, onMove, onEnd],
  );

  const keyboardConfig = useConfigFor('keyboard', props);
  const mouseConfig = useConfigFor('mouse', props);
  const touchConfig = useConfigFor('touch', props);
  const wheelConfig = useConfigFor('wheel', props);

  const keyboardRef = useRef(null);
  const mouseRef = useRef(null);
  const touchRef = useRef(null);
  const wheelRef = useRef(null);

  useLayoutEffect(function updateRefs() {
    const {innerRef} = props;
    if (typeof innerRef === 'function') {
      innerRef(gestureRef.current);
    } else if (innerRef && 'current' in innerRef) {
      innerRef.current = gestureRef.current;
    }
    keyboardRef.current = keyboardConfig ? gestureRef.current : null;
    mouseRef.current = mouseConfig ? gestureRef.current : null;
    touchRef.current = touchConfig ? gestureRef.current : null;
    wheelRef.current = wheelConfig ? gestureRef.current : null;
  });

  useKeyboardGesture(keyboardRef, gestureHandler, keyboardConfig);
  useMouseGesture(mouseRef, gestureHandler, mouseConfig);
  useTouchGesture(touchRef, gestureHandler, touchConfig);
  useWheelGesture(wheelRef, gestureHandler, wheelConfig);

  const {children: render} = props;
  return render({...state, gestureRef});
}

export default PageGesture;
