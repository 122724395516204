import {KeyboardGestureObservable} from '../gesture-observable';
import useRefCallback from '../use-ref-callback';
import {useObservableGestureEffect} from './utils';

function useKeyboardGesture(
  handlerOrProvidedRef,
  handlerOrConfig,
  maybeConfig,
) {
  let handler;
  let config;
  let providedRef = null;

  if ('current' in handlerOrProvidedRef) {
    providedRef = handlerOrProvidedRef;
    handler = handlerOrConfig;
    config = maybeConfig;
  } else {
    handler = handlerOrProvidedRef;
    config = handlerOrConfig;
  }

  const [ref, setRef] = useRefCallback(null);
  if (providedRef) setRef(providedRef.current);
  useObservableGestureEffect(KeyboardGestureObservable, ref, handler, config);
  if (!providedRef) return setRef;
}

export default useKeyboardGesture;
