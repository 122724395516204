import * as GestureCatcher from './GestureCatcher';
import * as GestureObservable from '../gesture-observable';
import * as keyboard from './useKeyboardGesture';
import * as mouse from './useMouseGesture';
import * as touch from './useTouchGesture';
import * as wheel from './useWheelGesture';
import * as gesture from './useGesture';

export const useKeyboardGesture = keyboard.default;
export const useMouseGesture = mouse.default;
export const useTouchGesture = touch.default;
export const useWheelGesture = wheel.default;
export const useGesture = gesture.default;

export const {Orientation} = GestureObservable;

export const {useConfigFor} = GestureCatcher;
export default GestureCatcher.default;
