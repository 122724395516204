import React from 'react';

export default () => {
  return (
    <svg width="100" height="102" viewBox="0 0 183 102">
      <g fill="none" fillRule="nonzero">
        <path
          fill="#FF7C1C"
          d="M38.448 69.418l13.5 15.81-.03.023-7.258 6.193-7.316-8.574c-3.6624136 1.608572-7.6229898 2.42638-11.623 2.4C10.866 85.274 0 74.607 0 59.749S10.866 34.2 25.721 34.2c2.771525-.0082643 5.5285858.3996148 8.179 1.21 3.3565043 1.0323039 6.460663 2.7536577 9.114 5.054 2.5776705 2.248991 4.6585438 5.0103201 6.11 8.108 1.7021773 3.7036669 2.4830558 7.7641666 2.276 11.835-.014.335-.318 4.273-.53 4.273H17.576v-9.792h22.589c-1.665-5.874-6.979-11.376-14.444-11.376-9.091 0-14.929 7.052-14.929 16.289 0 9.166 5.838 16.186 14.929 16.186 1.7564124.0002149 3.5020936-.2737497 5.174-.812l2.032-1.075c1.452543-.77122 2.7427592-1.8152766 3.8-3.075l1.48-1.761a.37299758.37299758 0 0 1 .02-.038l.191.225.03-.033zm78.045-50.683c-3.835507-.0100864-6.96101 3.075678-7 6.911 0 3.8687547 3.136245 7.005 7.005 7.005 3.868755 0 7.005-3.1362453 7.005-7.005-.003329-1.8491023-.746143-3.6200516-2.062923-4.9182352-1.31678-1.2981836-3.098114-2.0157347-4.947077-1.9927648zM91.4 64.928c0 7.795-4.658 12.358-11.311 12.358-6.845 0-10.742-4.373-10.742-12.167V36.6h-11.5v31.94c0 11.692 6.558 19.3 17.871 19.3 7.795 0 12.452-4.087 15.684-8.84v7.795H103V36.6H91.4v28.328zm18.974 21.863h11.5V36.6h-11.5v50.191zM183 61.6v.191c0 16.635-11.407 26.045-23.289 26.045-8.365 0-13.593-4.182-17.11-8.84v23H131.1V36.6h11.5v8.364c3.707-5.228 8.936-9.411 17.11-9.411C171.5 35.555 183 44.871 183 61.6zm-11.692 0c0-9.695-6.558-16.064-14.353-16.064S142.317 52 142.317 61.6v.191c0 9.6 6.844 16.065 14.638 16.065 7.889 0 14.353-6.179 14.353-16.065V61.6z"
        />
        <path
          fill="#182C3E"
          d="M77.313 19.26H69.05V8.221h8.184v1.735H70.99v2.87h5.535v1.735H70.99v2.964h6.324v1.735h-.001zm-21.967-7.854l6.087 7.853h1.625V8.221H61.15v7.632l-5.914-7.632h-1.8V19.26h1.908v-7.854h.002zm29.362 7.854h-1.939V8.221h4.919c1.1656527-.06472092 2.3098946.33226097 3.185 1.105.6087418.64088408.9378886 1.4973852.915 2.381v.031c.0499669 1.6006086-1.0520776 3.007841-2.618 3.343l2.98 4.179h-2.3l-2.712-3.847h-2.43v3.847zm0-5.567h2.839c1.388 0 2.271-.726 2.271-1.845v-.031c0-1.184-.853-1.83-2.287-1.83h-2.823v3.706zm-41.93 5.567h1.956v-9.241h3.5v-1.8h-8.956v1.8h3.5v9.241zM103 0v27.48H2V0h101zm-1.826 1.825H3.825v23.83h97.349V1.825zm-86.386 12.94h5.568V13h-5.568V9.987h6.277V8.221h-8.216V19.26h1.939v-4.495zm19.821 2.76h-6.324V14.56h5.536v-1.735h-5.536v-2.87h6.246V8.221h-8.184V19.26h8.262v-1.735z"
        />
      </g>
    </svg>
  );
};
