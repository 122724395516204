// @flow

import React, {Component} from 'react';
import {Block, Col} from 'jsxstyle';

import {LIGHT_GREY, GREY, ORANGE, WHITE} from '@styles/colors';
import CardSpan from '@components/CardSpan';
import Link from '@components/Link';
import {stringToUrl} from '@util';

type Props = {
  baths: number,
  beds: number,
  name: string,
  price: number,
  floor: string,
  slug: string,
  propertyName: string,
  image?: {
    url: string,
  },
};

type State = {
  hovered: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 500px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class UnitCard extends Component<Props, State> {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {
      baths,
      beds,
      name,
      floor,
      price,
      slug,
      propertyName,
      image,
    } = this.props;
    return (
      <Link
        href={`/properties/${stringToUrl(propertyName)}/unit/${slug}/`}
        style={{
          textDecoration: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          justifySelf: 'center',
          height: '330px',
          maxWidth: '430px',
          boxShadow: this.state.hovered
            ? '0 10px 50px 0 rgba(45, 50, 52, 0.25)'
            : 'none',
          transition: 'box-shadow 0.4s',
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleMouseEnter}
        onBlur={this.handleMouseLeave}
      >
        <Block
          component="img"
          maxWidth="140px"
          width="50%"
          padding="30px"
          objectFit="contain"
          props={{src: image && image.url && image.url, alt: `${name}`}}
          background={WHITE}
          mediaQueries={mediaQueries}
          smWidth="40%"
          smPadding="20px"
        />
        <Col
          width="45%"
          justifyContent="center"
          backgroundColor={LIGHT_GREY}
          padding="20px"
          mediaQueries={mediaQueries}
          smPadding="10px"
        >
          <CardSpan
            margin="0 0 10px 0"
            color={ORANGE}
            textTransform="uppercase"
            text={propertyName}
          />
          <CardSpan
            margin="0 0 10px 0"
            color={ORANGE}
            textTransform="uppercase"
            text={name}
          />
          <CardSpan text={floor} color={GREY} fontWeight="initial" />
          <CardSpan text={`${beds} bedroom`} color={GREY} margin="10px 0 0 0" />
          <CardSpan text={`${baths} bath`} color={GREY} margin="0 0 10px 0" />
          <CardSpan
            color={GREY}
            text={`$${price}/month`}
            fontWeight="initial"
            margin="10px 0 0 0"
          />
        </Col>
      </Link>
    );
  }
}
