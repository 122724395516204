const GESTURE_THRESHOLD = 0;
const CANCEL_THRESHOLD = 3;

export function parseConfig(config) {
  const orientation = config?.orientation;
  const preventDefault = config?.preventDefault ?? false;
  const passive = config?.passive ?? !preventDefault;
  const threshold = config?.threshold ?? (GESTURE_THRESHOLD || 0);
  const cancelThreshold =
    config?.cancelThreshold ?? (orientation ? CANCEL_THRESHOLD : undefined);
  return {
    ...config,
    orientation,
    preventDefault,
    passive,
    threshold,
    cancelThreshold,
  };
}
