import React from 'react';

export default () => (
  <svg width="78" height="72" viewBox="0 0 78 72">
    <path
      fill="#FF7C1C"
      fillRule="nonzero"
      d="M27.0491616 1.87543636L26.5822907.54152727l-2.4957097.87865455.466871 1.33390909 2.4957096-.87865455zM47.9366778 16.8497273l.2208709.3610182 14.2970968-5.031691C54.8603875 4.46627273 43.9173552.6185091 32.9306778 2.19132727c5.0443226 1.86614546 10.332 6.97349091 15.006 14.65840003zM17.5609681 27.5395818c-1.1347742-8.9458182-.1309355-16.2697091 2.6345806-20.87534544C10.6147745 12.3197455 4.45022616 22.1933273 3.31545197 32.9920182l14.29841933-5.0316909-.0529032-.4207455zm21.632129 23.1303818l-1.6571935-4.7410181H3.6209681c-1.82648387 0-3.30645162 1.4852181-3.30645162 3.3181818 0 .511.12564517.9901454.33064517 1.4228363H39.1930971zm5.3511613-4.7410181l1.6571936 4.7410181h4.0299032l18.3891613-18.6627818c-.1626774-.4141091-.41-.8016727-.7446129-1.1334909-1.2974516-1.2914364-3.3924194-1.2781636-4.6753226.0265455L48.3929681 45.9289455h-3.8487097zM.31451648 52.6608727v6.8381091H42.2786778l-2.3899033-6.8381091H.31451648zM4.69225842 71.1312h4.06825806l3.14245162-9.6413091H5.76487132L4.69225842 71.1312zm61.40212908-9.6413091L69.2368391 71.1312h4.0709032l-1.0739355-9.6413091h-6.1394193zm10.1719677-8.8290182l-10.6771936-7.7844545 5.7823226-5.8665455-3.4678064-3.4429454-16.8457097 17.0939454h-4.1608387l2.3912258 6.8381091h28.397129v-6.8381091h-1.419129zm-18.3481613 0l5.7902581-5.8771636 8.0598064 5.8771636H57.9181939zm-23.7866129-30.514L46.2411294 17.885C40.830452 8.99227273 34.2783875 3.37923636 28.7896778 3.37923636c-.8530645 0-1.6796774.13538182-2.4719033.41543637C20.4177423 5.8692 18.0146133 15.3459273 19.5289681 27.2860727l12.1055806-4.2605454L48.4458713 71.1312h2.8025484L34.131581 22.1468727z"
    />
  </svg>
);
